import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import RealTimeChart from "./RealTimeChart.js";
import WarningBox from "./MqttWarningBox.js";
import WarningLight from "./WarningLight.jsx";
let mqttCli = require("mqtt");

let client;
function AppMqtt({
  titles = ["free_disk_percentage", "cpu_load", "available_mem_percent", "free_disk_percentage"],
  url = "hardware",
  dataTypes = [
    "free_disk_percentage",
    "cpu_load",
    "available_mem_percent",
    "free_disk_percentage"
  ],
  MainHistory
}) {
  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
  const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);

  const [firstDataTypeArray, setFirstDataTypeArray] = useState([]);
  const [secondDataTypeArray, setSecondDataTypeArray] = useState([]);
  const [thirdDataTypeArray, setThirdDataTypeArray] = useState([]);
  const [fourthDataTypeArray, setFourthDataTypeArray] = useState([]);

  const [dataLabels, setDataLabels] = useState([]);

    // Now, you can use certificateBuffer in your MQTT configuration
    const optionsMqtt = {
      //      protocol: 'mqtts',
      username: dataOfServer.serverMqttUser,
      password: dataOfServer.serverMqttPassword,
      //     ca: [b]
       // Provide the certificate as an array of Buffers
    };


  useEffect(() => {
    function startMqttSubscribe() {
        client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);

        // Kanala abone ol
        client.subscribe(`${url}/${dataOfDevice.deviceID}/`, () => {
        console.log("we subscribed to :", `${url}/${dataOfDevice.deviceID}/`);
        });

        // Mesajları dinle
        let countOfAmount = 0;
        client.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
          countOfAmount++;
          let dateString = moment(dataAll.timestamp).format("LT");

            if (countOfAmount < 200) {
              setDataLabels((elements) => [...elements, dateString]);
              if (dataAll[dataTypes[0]] || dataAll[dataTypes[0]] === 0)
                setFirstDataTypeArray((elements) => [
                  ...elements,
                  dataAll[dataTypes[0]],
                ]);
              if (dataAll[dataTypes[1]] || dataAll[dataTypes[1]] === 0)
                setSecondDataTypeArray((elements) => [
                  ...elements,
                  dataAll[dataTypes[1]],
                ]);
              if (dataAll[dataTypes[2]]  || dataAll[dataTypes[2]] === 0)
                setThirdDataTypeArray((elements) => [
                  ...elements,
                  dataAll[dataTypes[2]],
                ]);
              if (dataAll[dataTypes[3]] || dataAll[dataTypes[3]] === 0)
                setFourthDataTypeArray((elements) => [
                  ...elements,
                  dataAll[dataTypes[3]],
                ]);
            } else {
              setDataLabels((elements) => [...elements.slice(1), dateString]);
              if (dataAll[dataTypes[0]] || dataAll[dataTypes[0]] === 0)
                setFirstDataTypeArray((elements) => [
                  ...elements.slice(1),
                  dataAll[dataTypes[0]],
                ]);
              if (dataAll[dataTypes[1]] || dataAll[dataTypes[1]] === 0)
                setSecondDataTypeArray((elements) => [
                  ...elements.slice(1),
                  dataAll[dataTypes[1]],
                ]);
              if (dataAll[dataTypes[2]]  || dataAll[dataTypes[2]] === 0)
                setThirdDataTypeArray((elements) => [
                  ...elements.slice(1),
                  dataAll[dataTypes[2]],
                ]);
              if (dataAll[dataTypes[3]] || dataAll[dataTypes[3]] === 0)
                setFourthDataTypeArray((elements) => [
                  ...elements.slice(1),
                  dataAll[dataTypes[3]],
                ]);
            }
        });

        return () => {
          if (client) {
              client.end();
          }
      };
    }

    startMqttSubscribe();
}, []);

  function decideToArrayValue(index) {
    if (index === 0) return firstDataTypeArray;
    else if (index === 1) return secondDataTypeArray;
    else if (index === 2) return thirdDataTypeArray;
    else if (index === 3) return fourthDataTypeArray;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {titles.map((title, index) => (
          <div className={"chart-wrapperOneBigChart"} key={index}>
            {index === 0 ? <WarningLight url={url} /> : null }
            <div
              className="topbarIconContainer"
              style={{
                float: "left",
                marginLeft: "2rem",
                marginTop: "-0.1rem",
                marginBottom: "-2.2rem",
              }}
            >
            {index === 0 ? (MainHistory) : null} 
            </div>

            <RealTimeChart
              dataValues={decideToArrayValue(index)}
              dataLabels={dataLabels}
              titles={titles}
              index={index}
            />
          </div>
        ))}
      </div>
    </>
  );
}
export default AppMqtt;
