import React from 'react'
import './aboutUs.css'

export default function AboutUs() {
    return (
        <div>
             <br/>
             <br/>
             <br/>
            <h1 className="AboutUsHeader">VeNIT Lab</h1>
            <h4  className="AboutUsDescription">Vehicular Networking and Intelligent 
            Transportation Systems Research Group, VeNIT Lab, focuses on novel and <br/>
            innovative researches in the field of Vehicular Networking and Intelligent 
            Transportation systems. Our aim is to bring in <br/> new and novel studies 
            comprising protocol, network and system design; testing and measurement of the systems; <br/> 
            developing applications and systems in the field.
            <br/>
            <br/>
            V2X communication and ITS are the promising 
            technologies of near future. VeNIT Lab aims the development and <br/>
            integration of communication systems, vehicular technologies, 
            and applications for vehicular ad hoc networks (VANets) <br/>
            and intelligent transportation systems (ITS). We focus on 
            providing network connectivity to the vehicle in order to  <br/>
            enhance the safety and comfort of the driving experience with 
            the use of the enabling technologies including vehicle  <br/>
            sensors, on-board network and V2V/V2I communication. 
            The goal of the VeNIT research lab is to:  <br/>
            <br/>
            <br/>
            Develop innovative communication architecture 
            and wireless network technologies for VANeTs.  <br/>
            <br/>
             Develop Technology for Intelligent Transport Systems (ITS).  <br/>
            <br/>
            Collaborate with local organizations to test and enhance 
            the developed novel technologies.  <br/>
            </h4>

<br></br>
<br></br>
<br></br>
<br></br>

        </div>
    )
}
