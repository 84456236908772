import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
 import { getTime } from "../../../actions/dataActions";
 import Grid from '@mui/material/Grid';
 import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';


export default function DateDeciderPage() {
  const dispatch = useDispatch();
  const dataOfTime = useSelector (dataOfTime  => dataOfTime.dataOfTime)
  

const [open, setOpen] = React.useState(false);

const handleClick = () => {
  setOpen((prev) => !prev);
};

const handleClickAway = () => {
  setOpen(false);
};

const styles = {
  position: 'fixed',
  width: 'auto',
  zIndex: '2',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.3)',
  background: 'rgba(0,0,0,0.85)',
  padding: '1rem',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '3rem'
};


    function setApplicationHistoryDate(){


        let convertedStartDateToTimeStamp = convertDateToTimeStamp(startDate)
        let convertedEndDateToTimeStamp = convertDateToTimeStamp(endDate)



        fetchData(convertedStartDateToTimeStamp, convertedEndDateToTimeStamp);
      }
    
  
    
    
      const [startDate, setStartDate] = useState(
        setHours(setMinutes(new Date(), 0), 9)
      );
    
      const [endDate, setEndDate] = useState(
        setHours(setMinutes(new Date(), 0), 9)
      );

    
      const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
    
        return currentDate.getTime() > selectedDate.getTime();
      };


      const fetchData = (startDate, endDate) => {
        //Fetch data from redux using time
        dispatch(getTime({
            startDate: startDate,
            endDate: endDate
        }))
      }


    function convertDateToTimeStamp(date){
// timestampFormat : "2021-10-07T11:55:32.534033"


let stringDate = String(date)
const dateElementArray = stringDate.split(" ")


let yearDate = dateElementArray[3]
let monthDate = convertStringMonthNameToIntNumber(dateElementArray[1])
let dayDate = dateElementArray[2]

let clockDate = dateElementArray[4]

let timeStampFormat = "" + yearDate + "-" + monthDate + "-" + dayDate + "T" + clockDate



return timeStampFormat;
    }

function convertStringMonthNameToIntNumber(stringMonthName){

  let monthNameInNumber = -12;

if(stringMonthName === "Jan")
monthNameInNumber = 1;
if(stringMonthName === "Feb")
monthNameInNumber = 2;
if(stringMonthName === "Mar")
monthNameInNumber = 3;
if(stringMonthName === "Apr")
monthNameInNumber = 4;
if(stringMonthName === "May")
monthNameInNumber = 5;
if(stringMonthName === "Jun")
monthNameInNumber = 6;
if(stringMonthName === "Jul")
monthNameInNumber = 7;
if(stringMonthName === "Aug")
monthNameInNumber = 8;
if(stringMonthName === "Sep")
monthNameInNumber = 9;
if(stringMonthName === "Oct")
monthNameInNumber = 10;
if(stringMonthName === "Nov")
monthNameInNumber = 11;
if(stringMonthName === "Dec")
monthNameInNumber = 12;


return monthNameInNumber
}
        return (
    <div>
      <div>
      <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
      <IconButton color="info" size="large"  onClick={handleClick} >
        <TimelapseIcon color="info" fontSize="inherit" />
        </IconButton>
        {open ? (
          <Box sx={styles}>
<h4 style={{color:"rgb(26, 118, 210)", marginLeft:"30px", marginBottom:"15px",textAlign:"center"}}>Time Settings</h4>
<Grid container spacing={2}>
<Grid item xs={1.8}>
            <h5 style={{color:"white", marginLeft:"30px", marginTop:"3px"}}>Start-Date </h5>
            </Grid>
            <Grid item xs={4.2}>
            <h5 style={{color:"white", marginLeft:"30px", marginTop:"3px"}}> {String(dataOfTime.startDateInfo)} </h5>
            </Grid>

            <Grid item xs={1.8}>
            <h5 style={{color:"white", marginLeft:"30px", marginTop:"3px"}}>End-Date </h5>
            </Grid>

            <Grid item xs={4.2}>
            <h5 style={{color:"white",  marginTop:"3px"}}> {String(dataOfTime.endDateInfo)} </h5>          
            </Grid>

</Grid>





            <Grid container spacing={2}>
            <Grid item xs={2}>
            <h5 style={{color:"white", marginLeft:"25px", marginTop:"3px"}}>Set-Start </h5>
            </Grid>
        <Grid item xs={4}>
    <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          filterTime={filterPassedTime}
          dateFormat="dd/MM/yyyy h:mm aa"
          maxDate = {new Date()}
          minDate = { new Date("2021/09/09") }
          placeholderText="Please Select The Start Date"
        />
    </Grid>
    <Grid item xs={1.5}>
              <h5 style={{color:"white", marginLeft:"25px", marginTop:"3px"}}>Set-End </h5>
            </Grid>
    <Grid item xs={4.5}>
    <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          showTimeSelect
          filterTime={filterPassedTime}
          dateFormat="dd/MM/yyyy h:mm aa"
          maxDate = {new Date()}
          minDate = { new Date("2021/09/09") }
          placeholderText="Please Select The End Date"
        />
      
          </Grid>
</Grid>
     <div style={{marginLeft:"45%", marginTop:"10px"}}>
      <Button variant="contained" size="small" color="secondary" onClick={ () => setApplicationHistoryDate()} >Set New Date</Button>
      </div>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
      </div>
        
    </div>
        )
}



