import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import RealTimeChart from "./RealTimeChart.js";
import WarningBox from "../../mqtt/MqttWarningBox.js";
import WarningLight from "../../mqtt/WarningLight.jsx";
let mqttCli = require("mqtt");

let client;
function AppMqtt({
  titles = ["free_disk_percentage", "cpu_load", "available_mem_percent", "free_disk_percentage"],
  url = "hardware",
  dataTypes = [
    "free_disk_percentage",
    "cpu_load",
    "available_mem_percent",
    "free_disk_percentage"
  ],
  timeLabel="time_start",
  MainHistory
}) {
  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
  const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice);

  const [firstDataTypeArray, setFirstDataTypeArray] = useState([]);
  const [secondDataTypeArray, setSecondDataTypeArray] = useState([]);
  const [thirdDataTypeArray, setThirdDataTypeArray] = useState([]);
  const [fourthDataTypeArray, setFourthDataTypeArray] = useState([]);

  const [dataLabels, setDataLabels] = useState([]);

  const options = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };

  useEffect(() => {
    function startMqttSubscribe() {
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, options);
      
      if (client.connected === false) {
        
      }
      client.subscribe(`${url}/${dataOfDevice.deviceID}/`, () => {
        //When a message arrives, print it to the console
        client.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
        
          let dateString = moment(dataAll[timeLabel]).format("LT");

          setDataLabels((elements) => [...elements, dateString]);
          if (dataAll[dataTypes[0]] || dataAll[dataTypes[0]] === 0)
            setFirstDataTypeArray((elements) => [
              ...elements,
              dataAll[dataTypes[0]],
            ]);
          if (dataAll[dataTypes[1]] || dataAll[dataTypes[1]] === 0)
            setSecondDataTypeArray((elements) => [
              ...elements,
              dataAll[dataTypes[1]],
            ]);
          if (dataAll[dataTypes[2]]  || dataAll[dataTypes[2]] === 0)
            setThirdDataTypeArray((elements) => [
              ...elements,
              dataAll[dataTypes[2]],
            ]);
          if (dataAll[dataTypes[3]] || dataAll[dataTypes[3]] === 0)
            setFourthDataTypeArray((elements) => [
              ...elements,
              dataAll[dataTypes[3]],
            ]);
        });
      });

      return client;
    }
    startMqttSubscribe();
  }, []);

  useEffect(() => {
    return () => {
      
      client.end();
    };
  }, []);

  function decideToArrayValue(index) {
    if (index === 0) return firstDataTypeArray;
    else if (index === 1) return secondDataTypeArray;
    else if (index === 2) return thirdDataTypeArray;
    else if (index === 3) return fourthDataTypeArray;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {titles.map((title, index) => (
          <div className={"chart-wrapperOneBigChart"} key={index}>
      {index === 0 ? <WarningLight url={url}/> : null }
            <div
              className="topbarIconContainer"
              style={{
                float: "left",
                marginLeft: "2rem",
                marginTop: "-0.1rem",
                marginBottom: "-2.2rem",
              }}
            >
              
            </div>
            <div
              style={{
                float: "left",
                marginTop: "-0.1rem",
                marginBottom: "-2.2rem",
              }}
            >
             {index === 0 ? (MainHistory) : null} 
            </div>
            <RealTimeChart
              dataValues={decideToArrayValue(index)}
              dataLabels={dataLabels}
              titles={titles}
              index={index}
            />
          </div>
        ))}
      </div>
    </>
  );
}
export default AppMqtt;

// <WarningBox warning={"Emulator Warning"} />