import React from 'react'
import { Line, Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";


Chart.register(annotationPlugin);
Chart.register(zoomPlugin);

function ChartBackGround() {

    const options = {
        plugins: {
          annotation: {
            annotations: {
              box1: {
                type: 'box',
                xMin: 1,
                xMax: 2,

                backgroundColor: 'rgba(255, 99, 132, 0.25)',
                label: {
                  enabled: true,
                  drawTime: 'afterDatasetsDraw',
                  display:true,
                  backgroundColor: 'rgba(0,245,245)',
                  content: ['This is my text',"this is second text", "this is third text", "this is four", "this is five"],
                }
              }
            }
          }
        }
      };

  return (
    <div>
        <Line
                      style={{
                        height: "70vh",
                        width: "70vw",
                        alignItems: "center",
                        alignContent: "center",
                        alignSelf: "center",
                        position:"relative",
                      }}          // chartSize      "chart-lineOneChart"  "chart-line-mqqt-link"
          data=   {{
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'].reverse(),
            datasets: [{
              label: 'My First Dataset',
              data: [65, 59, 80, 81, 56, 55, 40],
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }]
          }}
          options={options}
        />
    </div>
  )
}

export default ChartBackGround