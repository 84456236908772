import React, { useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addAnomaly, updateAnomaly } from '../../actions/dataActions';
import { useHistory } from 'react-router-dom';

let mqttCli = require("mqtt");

let client;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}





export default function TemporaryDrawer() {

  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
  const dataOfAnomaly = useSelector((dataOfDevice) => dataOfDevice.dataOfAnomaly);
  const anomalies = useSelector((dataOfDevice) => dataOfDevice.anomalies);
  const dispatch = useDispatch();
 

  const [anomalyArray, setAnomalyArray] = useState([
  ]);

  const handleRemoveItem = (anomalyID) => {
    setAnomalyArray(
      anomalyArray.filter((item) => item.anomalyID !== anomalyID)
    );
  };

  function addItemToAnomalyList(anomalyInfo, deviceID, topic , starting, ending, anomalyID, target_field ) {


    setAnomalyArray((elements) => [
      {

        anomalyInfo: anomalyInfo,
        deviceID: deviceID,
        topic: topic,
        starting: starting,
        ending: ending,
        anomalyID: anomalyID,
      }, 
...elements,
    ]
    );
  }

  const optionsMqtt = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };
  useEffect(() => {
    function startMqttSubscribe() {
      let anomalyIDList = [];
      let anomalyStartTimeList = [];
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
     
      if (client.connected === false) {
       
      }
      client.subscribe(`watmon/+/+/anomaly`, () => {
        //When a message arrives, print it to the console
        client.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
         
          if(anomalyIDList.includes(dataAll.first.id)){
            let updatedAnomaly = {
              anomalyID: dataAll.first.id,
              anomalyStartedTime: anomalyStartTimeList[anomalyIDList.indexOf(dataAll.first.id)],
              anomalyEndedTime: dataAll.anomaly_time_delta[0],
              sensorID: dataAll.sensor_id,
              confidence: dataAll.confidence,
              topic: dataAll.topic,
              target_field: dataAll.target_field,
            };

            dispatch(updateAnomaly(dataAll.first.id, updatedAnomaly))
            
          }else{
            anomalyIDList.push(dataAll.first.id);
            anomalyStartTimeList.push(dataAll.anomaly_time_delta[0]);

// buraya konacak kod 120 121
let anomaly = {
  anomalyID: dataAll.first.id,
  anomalyStartedTime: dataAll.anomaly_time_delta[0],
  anomalyEndedTime: dataAll.anomaly_time_delta[0],
  sensorID: dataAll.sensor_id,
  confidence: dataAll.confidence,
  topic: dataAll.topic,
  target_field: dataAll.target_field,
};
dispatch(addAnomaly(anomaly))
  addItemToAnomalyList(dataAll.app_id, dataAll.sensor_id, dataAll.topic, moment(dataAll.timestamp).format("LT"), moment(dataAll.timestamp).format("LT"), dataAll.first.id);

        }

        });
      });

      return client;
    }
    startMqttSubscribe();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      
      client.end();
    };
  }, []);
  const history = useHistory();
  const butonClick = () => {

    history.push('/anomaly');
  }
  


  function card(AnomalyTitle, DeviceID, Starting, Ending, anomalyID) {


    return (
      <React.Fragment>
        <CardContent>
          <Typography sx={{ fontSize: 18 }} gutterBottom>
            {AnomalyTitle}
          </Typography>
          <Typography sx={{ fontSize: 15, color: "blue" }}>
            DeviceID : {DeviceID}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 14 }}>
            Time Period:
            <br />
            Starting: {Starting}
            <br />
            Ending: {Ending}
          </Typography>
        </CardContent>
        <Button sx={{ float: "right" }} onClick={butonClick}>
          {anomalyID}
        </Button>
        <Button
          sx={{ float: "left" }}
          color="error"
          onClick={() => handleRemoveItem(anomalyID)}
        >
          Delete
        </Button>
      </React.Fragment>
    );
  }
  function deleteAllAnomalies(){
    setAnomalyArray(
      []
    );
  }

  // drawer kodu başlangıcı

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  useEffect(() => {
    if(dataOfAnomaly.anomalyData.toString() == "true"){
      setState({
        top: false,
        left: false,
        bottom: false,
        right: true,
      });
    }
   
  }, [dataOfAnomaly])


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider />
      <Box sx={{ width: "100%" }}> 
        <Button  sx={{ width: "100%" }} color="error"  onClick={() => deleteAllAnomalies() } >Delete All Anomalies </Button>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Anomaly" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <div
          style={{
            overflowY: "scroll",
            height: "93vh",
          }}
        >
          <TabPanel value={value} index={0}>
            {anomalyArray.map((element, index) => (
              <Card variant="outlined" key={index}>
                {card(
                  element.topic,
                  element.deviceID,
                  element.starting,
                  element.ending,
                  element.anomalyID
                )}
              </Card>
            ))}
          </TabPanel>
          <TabPanel value={value} index={1}>
          </TabPanel>
        </div>
      </Box>
    </Box>
  );

  // drawer kodu bitişi

  return (
    <div
      style={{
        alignContent: "center",
        alignItem: "center",
        marginTop: "0.5rem",
      }}
    >
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            sx={{ color: "white" }}
            onClick={toggleDrawer(anchor, true)}
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            alignItem=" center"
          >
            <Badge badgeContent={anomalyArray.length} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
