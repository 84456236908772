import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LineChartTraceRoute from "../../homepage/traceRoot/components/LineChartTraceRoute";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import StorageIcon from '@mui/icons-material/Storage';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';
  import Tooltip from '@mui/material/Tooltip';


let mqttCli = require("mqtt");

let client;
function AppMqtt() {
  const dataOfServer = useSelector((dataOfServer => dataOfServer.dataOfServer));
  const dataOfDevice = useSelector((dataOfDevice => dataOfDevice.dataOfDevice));

  const [dataList, setDataList] = useState()
  const [loading, setLoading] = useState(false);

  const optionsMqtt = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };

  useEffect(() => {
    function startMqttSubscribe() {
        client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
        client.subscribe(`watmon/trace_results/${dataOfDevice.deviceID}/list/`,  () => {
          //When a message arrives, print it to the console
          client.on("message", (topic, message, packet) => {
   
            let data = JSON.parse(message)
            console.log("dataAll mqttTraceResult:", data)
            console.log("dataAll mqttTraceResult:", data["hop_ip_address"])
        //  let dateString = moment(dataAll.timestamp).format("LT")
         
        const objectArray = data.hop_ip_address.map((ip, index) => {
          return {
            hop_ip_address: ip,
            delay_results: data.delay_results[index],
            loss_rate: data.loss_rate[index],
            pingable: data.pingable[index],
            timeout: data.timeout[index],
          };
        });

        setDataList(objectArray);
        setLoading(true);
          });
        }
      );

      return client;
    }
    startMqttSubscribe();
  }, []);

  useEffect(() => {
    return () => {
   
      client.end();
    };
  }, []);

  const [mapPopUp, setMapPopUp] = useState(new Map());

  const updateMapPopUp = (key, value) => {
    setMapPopUp((map) => new Map(map.set(key, value)));
  };

  const handleClick = (hopId) => {
    updateMapPopUp(hopId, !mapPopUp.get(hopId));
  };

  const handleClickAway = (hopId) => {
    updateMapPopUp(hopId, false);
  };

  function calculateDelayResults(delayResults) {
    let totalDelay = 0;
    for (let i = 0; i < delayResults.length; i++) {
      totalDelay += parseFloat(delayResults[i]);
    }
    let averageDelay = totalDelay * 1000;
    averageDelay = averageDelay / (delayResults.length * 1000.0);
    return averageDelay;
  }


  
  const styleOfSettingBlock = {
    position: "fixed",     // 'fixed' kullanarak ekranın her yerinde aynı konumda kalmasını sağlarız.
    top: "50%",            // Ekranın yatay eksende tam ortasında olmasını sağlar.
    left: "50%",           // Ekranın dikey eksende tam ortasında olmasını sağlar.
    transform: "translate(-50%, -50%)",  // Bileşeni tam ortada konumlandırmak için kaydırma.
    zIndex: "3",
    border: "1px solid rgba(0, 0, 0, 0.04)",
    boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.3)",
    background: "rgba(255,255,255,0.85)",
    borderRadius: "5px 50px 50px 50px",
  };


  return (
    <>
      <div>
      <TimelineItem>
<TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
              <TimelineSeparator>
           
              <Tooltip title= {`DeviceName: ${dataOfDevice.deviceName} and DeviceID: ${dataOfDevice.deviceID}`}>
                <TimelineDot sx={{ background: `black`  , borderRadius: "0%"  }}>
                  <DevicesOtherIcon
                    fontSize="large"
                    sx={{ color: `yellow` }}
                  />
                </TimelineDot>
                </ Tooltip>
                <ArrowDownwardIcon/>
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                DeviceIP: {dataOfDevice.deviceIP}
                </Typography>
              </TimelineContent>
            </TimelineItem>

       {loading && (
       <Timeline position="right">   
      {dataList.map((item, index) => (
                      <TimelineItem  key={index}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0" }}
                        variant="body2"
                        color="text.secondary"
                      ></TimelineOppositeContent>
                      <TimelineSeparator>
                      
                        <TimelineDot
                          sx={{ color: `${item["pingable"] ? "green" : "red"}`, 
                          background: `${item["timeout"] ? "red" : "green"}` }}
                        >
                          <CircleIcon />
                        </TimelineDot>
                        <ArrowDownwardIcon />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography component="span">
                          {item["hop_ip_address"]}
                        </Typography>
    
                        <ClickAwayListener
                          onClickAway={() => handleClickAway(item["hop_ip_address"])}
                        >
                          <div>
                            <Box sx={{ position: "relative" }}>
                              <div style={{}}>
                                <IconButton
                                  color="info"
                                  size="small"
                                  onClick={() => handleClick(item["hop_ip_address"])}
                                >
                                  <Typography>
                                    {" "}
                                    Delay:
                                     {calculateDelayResults(item["delay_results"]).toFixed(3) + "ms"}
                                  </Typography>
                                </IconButton>
                              </div>
                              {mapPopUp.get(item["hop_ip_address"]) ? (
                                <Box sx={styleOfSettingBlock}>
                                  <div>
                                    <LineChartTraceRoute
                                      YaxisLabel="Delay Results(ms)"
                                      XYaxisLabelFontSize={24}
                                      specialUrlPath="watmon/trace_results"
                                      primaryValue="delay_results"
                                      urlExtension={`&hop_ip_address=${item["hop_ip_address"]}`}
                                    />
                                  </div>
                                </Box>
                               ) : null}
                            </Box>
                          </div>
                        </ClickAwayListener>
                      </TimelineContent>
                    </TimelineItem>
      ))}
    </Timeline>
       )}


<TimelineItem>
            <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
              <TimelineSeparator>           
            
              <Tooltip title= {`DeviceName: Main Server`}>
                <TimelineDot sx={{ background: `black` , borderRadius: "0%" }}>
                  <StorageIcon
                    fontSize="large"
                    sx={{ color: `yellow`}}
                  />
                </TimelineDot>
     </Tooltip>
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px"}}>
                <Typography variant="h6" component="span">
                  ServerIP: {dataOfServer.serverIP}
                </Typography>
              </TimelineContent>
            </TimelineItem>
        </div>
    </>
  );
}
export default AppMqtt;
