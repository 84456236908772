export default function chartOptions(borderWidthValue, chartMaxData, chartMinData,chartStandardDeviation, YaxisLabel){

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio:true,
    elements: {
      line: {
        borderWidth: borderWidthValue,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "rgba( 255, 255, 255, 1)",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)",
        },
        title: {
          display: true,
          text: 'Time',
          color: "rgba( 255, 255, 255, 1)",
          font: {
            size: 12
          },
        },
      },
      y: {
        ticks: {
          color: "rgba(255, 255, 255, 1)",
        },
        grid: {
          drawBorder: true,
          drawTicks: true,
          color: "rgba(255, 255, 255, 0.35)",
        },
        title: {
          display: true,
          text: `${YaxisLabel}`,
          color: "rgba( 255, 255, 255, 1)",
          font: {
            size: 18
          },
        },
      },
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: chartMaxData,
            yMax: chartMaxData,
            borderColor: "#FF5733",
            borderWidth: 2,
            label: {
              rotation: "auto",
              backgroundColor: "red",
              borderColor: "black",
              borderRadius: 10,
              borderWidth: 3,
              borderDash: [6, 6],
              content: `max:${parseFloat(chartMaxData).toFixed(3)}`,
              enabled: true,
              position: "start",
            },
          },
          line2: {
            type: "line",
            yMin: chartMinData,
            yMax: chartMinData,
            borderColor: "#FF5733",
            borderWidth: 2,
            label: {
              rotation: "auto",
              backgroundColor: "red",
              borderColor: "black",
              borderRadius: 5,
              borderWidth: 2,
              borderDash: [6, 6],
              content: `Min: ${parseFloat(chartMinData).toFixed(3)}`,
              enabled: true,
              position: "start",
            },
          },


          line4: {
            type: "line",
            yMin: chartMinData,
            yMax: chartMinData,
            borderColor: "#FF5733",
            borderWidth: 2,
            label: {
              rotation: "auto",
              backgroundColor: "red",
              borderColor: "red",
              borderRadius: 5,
              borderWidth: 2,
              borderDash: [6, 6],
              content: `Standard Deviation:${chartStandardDeviation.toFixed(3)}`,
              enabled: true,
              position: "end",
            },
            

          },

        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          mode: "x",
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: "xy",
          speed: 100,
        },
      },
    },
  };

  return options;
}



export function marksOfChartLineBorder(){


const marksOfChartLineBorder = [
    {
      value: 0.1,
      label: 'Min',
    },
    {
      value: 2.5,
      label: 'Default',
    },
    {
      value: 5,
      label: 'Max',
    },
  ];
  return marksOfChartLineBorder
}


export function getStyleOfSettingBlock(){
const styleOfSettingBlock = {
  position: 'absolute',
  top:'relative',
  width: '20rem',
  zIndex: '3',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.3)',
  background: 'rgba(255,255,255,0.85)',
  padding: '30px',
  paddingRight: '50px',
  borderRadius: '5px 50px 50px 50px',
};
return styleOfSettingBlock
}
