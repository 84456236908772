import React, {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DateDeciderPage from "../../history/DateDecider";


function BinButtons( {funcSetBinType} 
 
  ) {
  const [selectedButton, setSelectedButton] = useState(1);
  const buttons = [
    <Button key="one" variant= {selectedButton === 1 ? "contained" : "outlined"} onClick={() => setSelectedButton(1)}>Standard</Button>,
    <Button key="two" variant= {selectedButton === 2 ? "contained" : "outlined"} onClick={() => setSelectedButton(2)}>Minute</Button>,
    <Button key="three" variant= {selectedButton === 3 ? "contained" : "outlined"} onClick={() => setSelectedButton(3)}>Hour</Button>,
    <Button key="four" variant= {selectedButton === 4 ? "contained" : "outlined"} onClick={() => setSelectedButton(4)}>Day</Button>,
    <Button key="five" variant= {selectedButton === 5 ? "contained" : "outlined"} onClick={() => setSelectedButton(5)}>Month</Button>,
  ];



  
  useEffect(() => {
    let binType = "standard"
    switch (selectedButton) {
      case 1:
        binType="standard"
        break;
      case 2:
        binType="minute"
        break;
      case 3:
        binType="hour"
        break;
      case 4:
        binType="Day"
        break;
      case 5:
        binType="month"
        break;
      default:
        binType="standard"
        break;
    }
    funcSetBinType(binType)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButton])
  
  

  return (
    <>
    <div
    style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        
    }}>

      <ButtonGroup size="small" aria-label="small button group" 
      >
        {buttons}
      </ButtonGroup>
    </div>
    <div style={{display:"inline-block", float:"right", maxHeight:"0rem", marginTop:"-2.5rem"}}>
    <DateDeciderPage/>
    </div>
    </>
  )
}

export default BinButtons