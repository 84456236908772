import React from 'react'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@mui/material/Tooltip';  
import './loading.css'
import Timeline from '@mui/lab/Timeline';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import StorageIcon from '@mui/icons-material/Storage';
import "./tracerouteTable.css";
export default function TraceRouteLibrary() {
    const dataOfDevice = useSelector((dataOfDevice => dataOfDevice.dataOfDevice));
    const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
    const decideToColor = {
        "true":"#00FF00",
        "false":"#FF0000"
    }
    const [info, setInfo] = useState({ 
      hop_ip_address: ["LOADING..."], 
      delay_results: [],
      loss_rate: [],
      pingable:[],
      timeout:[],
      timestamp:"",
      count: 0 })
      const [totalHop, setTotalHop] = useState([]);
      const [loading, setLoading] = useState(true);


    useEffect(() => {
        async function run() {
          //  let path= `http://172.16.11.13:5000/traceroute/${dataOfDevice.deviceIP}`
          let hopArray = [];
          let path= `http://${dataOfServer.serverIP}:5001/traceroute/${dataOfDevice.deviceIP}`
          console.log("path traceroute:", path)
            const response = await axios.get(path);
            let responseResult = response;
            setInfo({...info, ...responseResult.data})
            for(let i =0; i<responseResult.data.hop_ip_address.length; i++){
              hopArray.push(i);
            }
            setTotalHop(hopArray);
            if(responseResult.data.hop_ip_address.length > 0){
              setLoading(false);
            }
           
        }
    run()


    const interval = setInterval(() => {
      run() 
     }, 7000);        
     return () => clearInterval(interval); 

    }, [])

    function calculateDelayResults(delayResults){
      let totalDelay = 0;
      for(let i = 0; i<delayResults.length; i++){
        totalDelay += delayResults[i];
      }
      let averageDelay = totalDelay * 1000;
      averageDelay = averageDelay / (delayResults.length * 1000.0);
      return averageDelay;
    }
    
  return (
    <div>

   <div>

<Timeline>

<TimelineItem>
<TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
              <TimelineSeparator>
           
              <Tooltip title= {`DeviceName: ${dataOfDevice.deviceName} and DeviceID: ${dataOfDevice.deviceID}`}>
                <TimelineDot sx={{ background: `black`  , borderRadius: "0%"  }}>
                  <DevicesOtherIcon
                    fontSize="large"
                    sx={{ color: `yellow` }}
                  />
                </TimelineDot>
                </ Tooltip>
                <ArrowUpwardIcon/>
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                DeviceIP: {dataOfDevice.deviceIP}
                </Typography>
              </TimelineContent>
            </TimelineItem>

    {!loading  &&
      <div>
        
        {totalHop?.slice(0)
        .reverse().map((hopNumber) => (


<TimelineItem key={hopNumber}>
<TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
               
                 <Typography>
          {info.hop_ip_address[hopNumber] ? info.hop_ip_address[hopNumber].toString().replace(/'/g, '') : null}
          </Typography>
              </TimelineOppositeContent>
        <TimelineSeparator>

          
          <Tooltip title= "if inner circle is green, pingable is true, if it is red, the hop is not pingable. If outside circle is green, timeout is false. if it is red, timeout is true  ">
          <TimelineDot  sx={{ color:  info.pingable[hopNumber]   ?  `${decideToColor[info.pingable[hopNumber].toString()]}` : "red", background:`${decideToColor[(!info.timeout[hopNumber]).toString()]}` }}>
            <CircleIcon />
          </TimelineDot>
          </Tooltip>
          {
            
            <ArrowUpwardIcon/>
          }
          
        </TimelineSeparator>
        <TimelineContent>
        <Typography> Loss Rate:{info.loss_rate[hopNumber]}%</Typography>
          <Tooltip title= "DELAY RESULT ">
          <Typography> Delay:{(info.delay_results[hopNumber] ? info.delay_results[hopNumber].toString() : null)=== "null" ? "No result" :(info.delay_results[hopNumber] ? calculateDelayResults(info.delay_results[hopNumber]).toFixed(3) : null)}ms</Typography>
          </Tooltip>

        </TimelineContent>
      </TimelineItem>











  ))}
      </div>   
    }




            <TimelineItem>
            <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  ></TimelineOppositeContent>
              <TimelineSeparator>           
            
              <Tooltip title= {`DeviceName: Main Server`}>
                <TimelineDot sx={{ background: `black` , borderRadius: "0%" }}>
                  <StorageIcon
                    fontSize="large"
                    sx={{ color: `yellow`}}
                  />
                </TimelineDot>
     </Tooltip>
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  ServerIP: {dataOfServer.serverIP}
                </Typography>
              </TimelineContent>
            </TimelineItem>



  </Timeline>
   
   </div>
    </div>
  )
}
/*

       <table class="content-table">
      <thead>
        <tr>
          <th>Hop Number</th>
          <th>Hop IP</th>
          <th>Delay</th>
          <th>Loss Rate</th>
          <th>Pingeable</th>
          <th>Time Out</th>
        </tr>
      </thead>
      <tbody>
      {totalHop?.slice(0)
        .reverse().map((hopNumber) => (
        <tr>
          <td>{hopNumber}</td>
          <td>{info.hop_ip_address[hopNumber].toString().replace(/'/g, '')}</td>
          <td>{info.delay_results[hopNumber].toString() === "null" ? "No result" : calculateDelayResults(info.delay_results[hopNumber]).toFixed(3)}ms</td>
          <td>{info.loss_rate[hopNumber]}%</td>
          <td>{info.pingable[hopNumber].toString()}</td>
          <td>{info.timeout[hopNumber].toString()}</td>
        </tr>
          ))}

      </tbody>
    </table>
    
*/