import moment from "moment";

export function convertFullDateLabeltoMonthAndHour(fullDateArray){
    let i;
    let newFormatArray = [];
      for(i=0; i< (fullDateArray.length) ; ++i ){
        newFormatArray.push(moment(fullDateArray[i]).format("DD/MM HH:mm"))
      }
    return newFormatArray;
    }
    
 export   function getStandardDeviation (array) {
      if (!array || array.length === 0) {return 0;}
      const n = array.length
      const mean = array.reduce((a, b) => a + b) / n
      return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
    }
    
    
export  function calculateEachMeanofArrayElement(array){
      let meanArray = [];
      let currentMean=0;
      let i;
      for(i=0; i<array.length; i++){
        currentMean = (array[i] + (currentMean * i)) / ((i+1) * 1.0)
        meanArray.push(currentMean)
      }
      return meanArray;
    }
    
    
    