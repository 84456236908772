import React, {useState} from 'react'
import { useEffect } from 'react';
import GaugeChart from "./GaugeChart"
import axios from "axios";
import { useSelector } from "react-redux";

let mqttCli = require("mqtt");

let client;

function DevicePopUpGauges() {
  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
  const dataOfDevice = useSelector((dataOfDevice => dataOfDevice.dataOfDevice));

  const optionsMqtt = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };


useEffect(() => {
  function startMqttSubscribe() {
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
    
      client.subscribe(`watmon/hardware/${dataOfDevice.deviceID}/`,  () => {                   // tekli cihazlarda sonda slash olmak zorunda.
        //When a message arrives, print it to the console
      
        client.on("message", (topic, message, packet) => {
        
          let dataAll = JSON.parse(message)
      
          setState({
            memory: dataAll.available_mem_percent,
            cpu: dataAll.cpu_load,
            storage: dataAll.free_disk_percentage,
          });

        });
      }
    );

    return client;
  }
  startMqttSubscribe();
}, [dataOfDevice.deviceID]);

useEffect(() => {
  return () => {

    client.end();
  };
}, [dataOfDevice.deviceID]);




const [state, setState] = useState({
  memory: 0,
  cpu: 0,
  storage: 0,
});


  return (
    <>
            <div
            style={{ display: "inline"}}
            >
<GaugeChart redPercentage= {(100 - state.memory)} topic="memory" tooltip={`available memory ${(state.memory)}%`}     />
<GaugeChart redPercentage= {(state.cpu)} topic="cpu" tooltip={`available cpu ${(100 - state.cpu)}%`}  />
<GaugeChart redPercentage= {(100 - state.storage)} topic="storage" tooltip={`avaliable disk ${(state.storage)}%`}  />
</div>
   </>
  )
}

export default DevicePopUpGauges