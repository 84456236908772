import React, { useState} from "react";
import { Line, Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import options , {marksOfChartLineBorder, getStyleOfSettingBlock} from "./ChartOptions"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Slider from '@mui/material/Slider';

Chart.register(annotationPlugin);

const ChartComponent = ({
  dataValues,
  dataLabels,
  deviceList,
  titles,
  index
}) => {

  const [borderWidthValue, setBorderWidthValue] = useState(2.5);
  const [chartPointRadiusValue, setChartPointRadiusValue] = useState(0.5);
  const [borderLineShow, setBorderLineShow] = useState(true);
  const [backGroundShow, setBackGroundShow] = useState(false);



  function changeChartRangeLineDrawingBorder(e) {
    setBorderWidthValue(e.target.value);
  }


  function changeChartRangePointRadius(e) {
    setChartPointRadiusValue(e.target.value);
  }


  function disableChartLine(e) {
    setBorderLineShow(e.target.checked);
  }

  function fillChartBackGround(e) {
    setBackGroundShow(e.target.checked);
  }


  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  
  const handleClickAway = () => {
    setOpen(false);
  };

  function decideHeighOfThePage(length){
  if(length === 1) return "calc(90vh - 128px)"
  if(length === 2) return "calc(55vh - 128px)"
  if(length === 3) return "calc(40vh - 128px)"
  if(length === 4) return "calc(33vh - 128px)"
  }


  const color = [
    "rgb(0, 63, 92)",
    "rgb(122, 81, 149)",
    "rgb(239, 86, 117)",
    "rgb(255, 166, 0)",
    "rgb(134, 200, 188)",
    "rgb(255, 212, 178)",
    "rgb(255, 209, 232)",
    "rgb(223, 211, 195)",
    "rgb(232, 243, 214)",
    "rgb(101, 100, 124)",
    "rgb(183, 196, 207)",
    "rgb(150, 126, 118)",
  ];

  const backGroundColor = [
    "rgb(0, 63, 92, 0.2)",
    "rgb(122, 81, 149, 0.2)",
    "rgb(239, 86, 117, 0.2)",
    "rgb(255, 166, 0, 0.2)",
    "rgb(134, 200, 188)",
    "rgb(255, 212, 178)",
    "rgb(255, 209, 232)",
    "rgb(223, 211, 195)",
    "rgb(232, 243, 214)",
    "rgb(101, 100, 124)",
    "rgb(183, 196, 207)",
    "rgb(150, 126, 118)",
  ];

  const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
  function prepareDatasets(deviceList, twoDimensionalArray) {
    const datasetsTwo = deviceList.map((title, index) => ({
      label: title,
      data: twoDimensionalArray[index],
      borderColor: color[index],
      backgroundColor: backGroundColor[index],
      yAxisID: "y",
      order: 2,
      showLine: borderLineShow,
      pointRadius: chartPointRadiusValue,
      fill: backGroundShow,
      segment: {
        borderColor: ctx => skipped(ctx, color[index]),
        borderDash: ctx => skipped(ctx, [1, 2]),
    //    backgroundColor: ctx => skipped(ctx, color[index]),
      },
    }));

   
    return datasetsTwo;
  }

  return (
    <div 
    style={{
      height: `${decideHeighOfThePage(titles.length)}`,
      width: "80vw",
      alignItems: "center",
      alignContent: "center",
      alignSelf: "center",
    }}    
    >
     
     <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{position:'absolute'}}>
<div style={{}}>
      <IconButton color="info" size="small"  onClick={handleClick} >
        <SettingsIcon color="info"/>
        </IconButton>
        </div>
        {open ? (
          <Box sx={getStyleOfSettingBlock}>
            <div>
                 <h4 style={{textAlign:'center'}}>Settings</h4>

       

              <label>Main Border Width Range</label>
              <Slider
  value={borderWidthValue} 
  onChange={changeChartRangeLineDrawingBorder}
  step={0.3}
  marks = { marksOfChartLineBorder()}
  min={0.1}
  max={5}
  valueLabelDisplay="auto"
  size="small"
/>

              <label>Point Radius Range</label>
              <Slider
                min={0.1}
                max={5}
                step={0.2}
                value={chartPointRadiusValue}
                valueLabelDisplay="auto"
                marks = { marksOfChartLineBorder() }
                onChange={changeChartRangePointRadius}
                size="small"
           
                />


<input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {borderLineShow}
                onClick={(e) => disableChartLine(e)}
              ></input>
                    <label style={{width: "auto", marginRight:"110px"}} >Show ChartLine</label>
            
              <input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {backGroundShow}
                onClick={(e) => fillChartBackGround(e)}
              ></input>
                <label>Fill ChartBackGround</label>
            </div>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>

        <Line
        style={{
          height:  `${decideHeighOfThePage(titles.length)}`,
          width: "80vw",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}         // chartSize      "chart-lineOneChart"  "chart-line-mqqt-link"           
          data={{
            labels: dataLabels,
            datasets: prepareDatasets(deviceList , dataValues )
          }}
          options={options(borderWidthValue, 
            titles[index])}
        />
      </div>

  );
};
export default ChartComponent;
