import React from 'react'
import MainMqtt from './mqtt/MainMqqt'
import MainHistory from './history/MainHistory'


function MainChart({titles, url, dataTypes}) {
  return (
    <>
 <MainMqtt
  MainHistory={
 
    <MainHistory 
     titles = {titles}
     url = {url}
     dataTypes = {dataTypes}  
     />
   } 


 />


    </>
  )
}

export default MainChart