import React from 'react'
import TraceRouteLibrary from './TraceRouteLibrary'
import RealTimeTraceRoute from '../../mqtt-pages/traceroot/mqttTraceroot'

function TraceRoute() {
  return (
    <div style= {{ display:"flex" , justifyContent:"space-between"}} >
      <div  style={{ marginRight: "60px" }}>
      <RealTimeTraceRoute/>
      </div>
<div style={{ marginLeft: "60px" }}>
  <TraceRouteLibrary/>
</div>
</div>
  )
}

export default TraceRoute