export default function chartOptions(borderWidthValue, chartMaxData, chartMinData,chartMeanData, chartYAxisLabel){


    const options = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            borderWidth: borderWidthValue,
          },
        },
        scales: {
          x: {
            ticks: {
              color: "rgba( 0, 0, 0.2)",
            },
            grid: {
              color: "rgba(0, 0, 0, 0.2)",
            },
            title: {
              display: true,
              text: 'Time',
              font: {
                size: 17
              },
            },
          },
          y: {
            ticks: {
              color: "rgba(0, 0, 0, 1)",
            },
            grid: {
              drawBorder: true,
              drawTicks: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
            title: {
              display: true,
              text: chartYAxisLabel,
              font: {
                size: 17
              },
            },
          },
        },
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              line1: {
                type: "line",
                yMin: chartMaxData,
                yMax: chartMaxData,
                borderColor: "#FF5733",
                borderWidth: 2,
                label: {
                  rotation: "auto",
                  backgroundColor: "red",
                  borderColor: "black",
                  borderRadius: 10,
                  borderWidth: 3,
                  borderDash: [6, 6],
                  content: `Max: ${parseFloat(chartMaxData).toFixed(3)}`,
                  enabled: true,
                  position: "start",
                },
              },
              line2: {
                type: "line",
                yMin: chartMinData,
                yMax: chartMinData,
                borderColor: "#FF5733",
                borderWidth: 2,
                label: {
                  rotation: "auto",
                  backgroundColor: "red",
                  borderColor: "black",
                  borderRadius: 5,
                  borderWidth: 2,
                  borderDash: [6, 6],
                  content: `Min: ${parseFloat(chartMinData).toFixed(3)}`,
                  enabled: true,
                  position: "start",
                },
              },
    
              line3: {
                type: "line",
                yMin: chartMeanData,
                yMax: chartMeanData,
                borderColor: "#FF5733",
                borderWidth: 1,
                label: {
                  rotation: "auto",
                  backgroundColor: "red",
                  borderColor: "black",
                  borderRadius: 5,
                  borderWidth: 2,
                  borderDash: [6, 6],
                  content: `Mean: ${parseFloat(chartMeanData).toFixed(3)}`,
                  enabled: true,
                  position: "start",
                },
              },
    
    
            },
          },
        },
      };
    

return options;
}




export function marksOfChartLineBorder(){


    const marksOfChartLineBorder = [
        {
          value: 0.1,
          label: 'Min',
        },
        {
          value: 2.5,
          label: 'Default',
        },
        {
          value: 5,
          label: 'Max',
        },
      ];
      return marksOfChartLineBorder
    }
    
    
    export function getStyleOfSettingBlock(){
    const styleOfSettingBlock = {
      position: 'absolute',
      top:'relative',
      width: '20rem',
      zIndex: '3',
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.3)',
      background: 'rgba(255,255,255,0.85)',
      padding: '30px',
      paddingRight: '50px',
      borderRadius: '5px 50px 50px 50px',
    };
    return styleOfSettingBlock
    }
    