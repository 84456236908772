

const initialDevice = {
  deviceID: "",
  deviceName: "",
  deviceIP: "",
  serverIPD: "",
  loading: false,
};

// initialState ile initialDevice değiştirildi
const dataOfDevice = (state = initialDevice, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING":
      return {
        ...state,
        loading: true,
      };
    case "REJECTED":
      return {
        ...state,
        loading: false,
      };
    case "SUCCESS":
      return {
        ...state,
        deviceID: payload.deviceID,
        deviceName: payload.deviceName,
        deviceIP: payload.deviceIP,
        serverIPD: payload.serverIPD,
        loading: payload.loading,
      };
    default:
      return state;
  }
};

const initialTime = {
  startDateInfo: "Current Date",
  endDateInfo: "Current Date",
};

const dataOfTime = (state = initialTime, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING-TIME":
      return {
        ...state,
      };
    case "REJECTED-TIME":
      return {
        ...state,
      };
    case "SUCCESS-TIME":
      return {
        ...state,
        endDateInfo: payload.endDateInfo,
        startDateInfo: payload.startDateInfo,
      };
    default:
      return state;
  }
};


const initialTimeLimitOfListeningDevice = {
  yellowTimeInfo: 150,
  redTimeInfo: 170,
};

const setTimeLimitOfListeningDevice = (
  state = initialTimeLimitOfListeningDevice,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING-TIMELIMITDEVICE":
      return {
        ...state,
      };
    case "REJECTED-TIMELIMITDEVICE":
      return {
        ...state,
      };
    case "SUCCESS-TIMELIMITDEVICE":
      return {
        ...state,
        yellowTimeInfo: payload.yellowTimeInfo,
        redTimeInfo: payload.redTimeInfo,
      };
    default:
      return state;
  }
};

const initialThemeDecider = {
  themeDecider: "light",
};

const setThemeDecider = (state = initialThemeDecider, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SUCCESS-THEMEDECIDER":
      return {
        ...state,
        themeDecider: payload.themeDeciderCurrent,
      };
    default:
      return state;
  }
};

const initialServer = {
  serverIP: "185.137.122.191",
  serverPORT: "",
  serverWs: "wss",
  serverMqttPort: "9001",
  serverMqttUser: "yavuz",
  serverMqttPassword: "",
  serverToken: "",
  serverHttp: "https"
};

const dataOfServer = (state = initialServer, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING-SERVER":
      return {
        ...state,
      };
    case "REJECTED-SERVER":
      return {
        ...state,
      };
    case "SUCCESS-SERVER":
      return {
        ...state,
        serverIP: payload.serverIP,
        serverPORT: payload.serverPORT,
        serverMqttPort:  payload.serverMqttPort,
        serverMqttUser: payload.serverMqttUser,
        serverMqttPassword: payload.serverMqttPassword,
        serverToken: payload.serverToken,
        serverHttp: payload.serverHttp,
        serverWs: payload.serverWs,
      };
    default:
      return state;
  }
};

const Initialenvironment = {
  environmentID: {},
  environmentName: "Current EnvironmentName",
  isEnvironmentSelected: false,
  deviceList: [],
};

const dataOfEnvironment = (state = Initialenvironment, action) => {
  const { type, payload } = action;
  if (type === "ENVIRONMENT-SELECTED") {
    return {
      ...state,
      environmentID: payload.environmentID,
      environmentName: payload.environmentName,
      isEnvironmentSelected: payload.isEnvironmentSelected,
      deviceList: payload.deviceList,
    };
  } else {
    return state;
  }
};

const initialAnomaly = {
  anomalyID: "ID anomaly",
  anomalyData: "ID DATA ANOMALY",
};

const dataOfAnomaly = (state = initialAnomaly, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING-ANOMALY":
      return {
        ...state,
      };
    case "REJECTED-ANOMALYE":
      return {
        ...state,
      };
    case "SUCCESS-ANOMALY":
      return {
        ...state,
        anomalyID: payload.anomalyID,
        anomalyData: payload.anomalyData,
      };
    default:
      return state;
  }
};









const initialState = {
  anomalies: []
};

function anomalies(state = initialState.anomalies, action) {
  switch (action.type) {
    case "ADD_ANOMALY":
      return [...state, action.payload];
    case "CLEAR_ANOMALIES":
      return [];
      case "REMOVE_ANOMALY":
      return state.filter(anomaly => anomaly.anomalyID !== action.payload);
      case "UPDATE_ANOMALY":
      return state.map(anomaly =>
        anomaly.anomalyID === action.payload.anomalyID ? action.payload.updatedAnomaly : anomaly
      );
    default:
      return state;
  }
}

export {
  dataOfDevice,
  dataOfTime,
  setTimeLimitOfListeningDevice,
  setThemeDecider,
  dataOfServer,
  dataOfEnvironment,
  anomalies,
  dataOfAnomaly
};




