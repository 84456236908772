import React, { useEffect, useState} from "react";
import {  Bar, Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import options from '../../history/ChartComponentOptionsOld'
import {convertFullDateLabeltoMonthAndHour, getStandardDeviation, calculateEachMeanofArrayElement } from '../../history/ArrayFunctions'
import moment from "moment";
import BinButtons from './BinButtons.jsx'

Chart.register(annotationPlugin);
Chart.register(zoomPlugin);

// min and max slider in isimleri yanıltmasın, min en sonda olan, max ise geçmişe gidebilmemize yardım eder.

const ChartComponent = ({
  labels,
  receivedwholeDataSet
}) => {


  
  
  const [dataValue, setDataValue] = useState()
  const [dataLabel, setDataLabel] = useState([])
  const [binType, setBinType] = useState("standard")
 
 // minute


 useEffect(() => {
  const wholeDataSet = JSON.parse(JSON.stringify(receivedwholeDataSet))
  
  if(wholeDataSet){
    
const barData =[];       // buradaki değişiklikler direk olarak büyük datadaki arrayi değiştirecek. çünkü aynı pointer ı kullanmaktadırlar.
const lineData = wholeDataSet[wholeDataSet.length-1].data  // aynısı line için geçerli değildir.
  for(let i=0; i<(wholeDataSet.length-1); i++){
 barData.push(wholeDataSet[i].data)
  }

let mockupValue = lineData;
let mockupLabel = labels;
let holdingBinValue = [];
let holdingBinLabel = [];
let barIterater = 0;
if(binType === "standard"){
    setDataValue(wholeDataSet)
    setDataLabel(convertFullDateLabeltoMonthAndHour(labels))
}
else{
  for(let i=0; i< mockupLabel.length; i++){
  let mean = mockupValue[i];
  let j = 1;
  let counterOfWhileLoop = 1;
  let date = mockupLabel[i];
  
  while(true){
  if( moment(mockupLabel[i]).isSame(mockupLabel[i+j], binType)){
      mean = (mockupValue[i+j] + mean * counterOfWhileLoop) / (counterOfWhileLoop+1)


      


     
      
      
      for (let i = 0; i < barData.length; i++) {
          
          let subArray = barData[i];
          
          let first = subArray[0 + barIterater] * counterOfWhileLoop;
          let second = subArray[1 + barIterater];
          subArray.splice(0+barIterater, 2 , ((first + second) * 1.000 )/ (counterOfWhileLoop+1));

          
         /*

          let first = subArray[0 + barCounter] * counterOfWhileLoop;
          let second = subArray[1 + barCounter];
          subArray.splice(0+barCounter, 2+barCounter , ((first + second) * 1.0 )/ (counterOfWhileLoop+1));
  */
          
      }


      j++;
      counterOfWhileLoop++;
     if((i+j+1) > (mockupLabel.length)){
      holdingBinValue.push(mean);
      holdingBinLabel.push(date);
      barIterater++;
    
      break;
     }
     
  }else{         
      holdingBinValue.push(mean);
      holdingBinLabel.push(date);
      barIterater++;
    
      break;
  }

  }
  i = i + j - 1;
  
  }

//  setDataValue(holdingBinValue)
lineData.splice(0, lineData.length);
lineData.push(...holdingBinValue);

  setDataValue(wholeDataSet)
  setDataLabel(convertFullDateLabeltoMonthAndHour(holdingBinLabel))

  }





/*

let arr = [[1, 2, 3, 4], [5, 6, 7, 8], [9, 10, 11, 12]];
let barIterator = 0;

for (let i = 0; i < arr.length; i++) {
    let subArray = arr[i];
    let first = subArray[0];
    let second = subArray[1];
    subArray.splice(0, 2, first + second);
    barIterator++;
}

*/










  }
 }, [receivedwholeDataSet, labels, binType])
 




 function funcSetBinType(receivedBinType){
  setBinType(receivedBinType)
}










// const [chartNoConnectionAreas, setChartNoConnectionAreas] = useState([]);
/*
    useEffect(() => {

        let mockupValue = fetchedDataValue;
        let mockupLabel = fetchedDataLabel;
        let holdingBinValue = [];
        let holdingBinLabel = [];
        if(binType === "standard"){
            setDataValue(fetchedDataValue)
            setDataLabel(convertFullDateLabeltoMonthAndHour(fetchedDataLabel))
        }
        else{
        for(let i=0; i< mockupLabel.length; i++){
        let mean = mockupValue[i];
        let j = 1;
        let counterOfWhileLoop = 1;
        let date = mockupLabel[i];
        while(true){
        if( moment(mockupLabel[i]).isSame(mockupLabel[i+j], binType)){
            mean = (mockupValue[i+j] + mean * counterOfWhileLoop) / (counterOfWhileLoop+1)
            j++;
            counterOfWhileLoop++;
           if((i+j+1) > (mockupLabel.length)){
            holdingBinValue.push(mean);
            holdingBinLabel.push(date);
            break;
           }
           
        }else{         
            holdingBinValue.push(mean);
            holdingBinLabel.push(date);
            break;
        }
        }
        i = i + j - 1;
        
        }

        setDataValue(holdingBinValue)
        setDataLabel(convertFullDateLabeltoMonthAndHour(holdingBinLabel)) 
        }
     
    },[binType, fetchedDataValue, fetchedDataLabel, wholeDataSet]);
*/
































const options = {
  animation: false,
  plugins: {
    title: {
      display: true,
      text: "PROTOCOL DENSITY",
    },
    autocolors: false,
    zoom: {
      zoom: {
        wheel: {
          enabled: true, // SET SCROOL ZOOM TO TRUE
        },
        mode: "x",
        speed: 100,
      },
      pan: {
        enabled: true,
        mode: "xy",
        speed: 100,
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      display: true,
      position: "left",
      ticks: {
        color: "rgba(0, 0, 0, 1)",
      },
      grid: {
        drawBorder: true,
        drawTicks: true,
        color: "rgba(0, 0, 0, 0.2)",
      },
      title: {
        display: true,
        text: "PROTOCOL DENSITY",
        font: {
          size: 17,
        },
      },
    },

    y1: {
      type: "linear",
      display: true,
      position: "right",
      title: {
        display: true,
        text: "Total Retransmissions",
        font: {
          size: 15,
        },
      },
      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },

};
  return (
<div
    style={{
      height: "70vh",
      width: "70vw",
      alignItems: "center",
      alignContent: "center",
      alignSelf: "center",
      position:"relative",
    }} 
>

<BinButtons funcSetBinType= {funcSetBinType} />
<Bar
            style={{
              height: `70vh`,
              width: "70vw",
              alignItems: "center",
              alignContent: "center",
              alignSelf: "center",
            }}
            options={options}
            data={{
              labels :dataLabel,
              datasets: dataValue,
            }}
          />
      </div>
  );
};


export default ChartComponent;
