import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ChartHistorySlider from "../history/ChartHistorySlider";
import { Radar } from "react-chartjs-2";
export default function MainHistory({
  titles = [ "Connection Re-established",
  "Invalid URL Error",
  "Connection Timeout",
 "Connection Error",
 "HTTP Error",
 "Read Timeout Error",
 "Unexpected Error",
 "Connection Exists. Server is down.",
 "Wireless Connectivity doesn't exist",
 "Program Started"
 ],
 url = "watmon/connection_states",
  dataType = "state",

}) {


    const dataOfTime = useSelector((dataOfTime) => dataOfTime.dataOfTime);
    const [limit, setLimit] = useState(200);
    const [offset, setOffset] = useState(0);
    const [maxCount, setMaxCount] = useState(0);
    const [startDateOfChart, setStartDateOfChart] = useState(" ");
    const [endDateOfChart, setEndDateOfChart] = useState(" ");
    const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer); 
    const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);

    const [dataTypeCounter, setDataTypeCounter] = useState([0,0,0,0,0,0,0,0,0,0])

  useEffect(() => {
  functionOfStartDateOfChart(String(dataOfTime.startDateInfo));
  functionOfEndDateOfChart(String(dataOfTime.endDateInfo));
  }, [dataOfTime]);
  
  useEffect(() => {
    setLimit(200)
  }, [startDateOfChart, endDateOfChart]);
  
    useEffect(() => {
      async function run() {
        const counterArray = [0,0,0,0,0,0,0,0,0,0]
        let path;
        if(startDateOfChart === "Current Date"){
          path= `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${url}/?sensor_unique_identifier=${dataOfDevice.deviceID}&limit=${limit}&offset=${offset}`
        }
        else{
          path = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${url}/?sensor_unique_identifier=${dataOfDevice.deviceID}&timestamp__range=${startDateOfChart},${endDateOfChart}&limit=${limit}&offset=${offset}`
        
        }
        const response = await axios.get(path);
        setMaxCount((response.data.count-1));
        const dataAll = response.data.results;
        for (let i = 0; i < limit; i++) {
          if (typeof dataAll[i] == "undefined") {     
            break;
          }
          counterArray[ dataAll[i][dataType] ] =  counterArray[ dataAll[i][dataType] ] + 1;
          

          if (i === limit - 1) {
            break;
          }
        }
        
  
        updateElement(counterArray)

       
      }
      run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, startDateOfChart, endDateOfChart]);
  

    function functionOfSetLimit(valueOfLimit) { setLimit(valueOfLimit); }
    function functionOffset(valueOfOffset) { setOffset(valueOfOffset); }
    function functionOfStartDateOfChart(valueOfStartDate) { setStartDateOfChart(valueOfStartDate); }
    function functionOfEndDateOfChart(valueOfEndDate) {setEndDateOfChart(valueOfEndDate);}
  
  function updateElement(array) {
    const arr = [...array];
   
    setDataTypeCounter(arr);
  }


  const options = {
    animation:false,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      beginAtZero: true,
    },
    scales: {
      r: {

        pointLabels: {
          color: "rgb(0, 0 , 0, 1)",
          font: {
            size: 18
        }
        }
    },

    }
  };



  return (

    <div>
      <div className={"chart-wrapperPopUp-Radar"}>
<div
style={{
  height: "70vh",
  width: "70vw",
  alignItems: "center",
  alignContent: "center",
  alignSelf: "center",
  position:"relative",
}} >


      <Radar
 style={{
  height:  `70vh`,
  width: "70vw",
  alignItems: "center",
  alignContent: "center",
  alignSelf: "center",
}}         // chartSize      "chart-lineOneChart"  "chart-line-mqqt-link"     
  data={{
    labels: titles,
    datasets: [
      {
        label: '',
        data: dataTypeCounter,
        backgroundColor: 'rgba(255 , 234 , 147 , 0.4)',
        borderColor: 'rgb(240, 185 , 11)',
        borderWidth: 1,
        tension: 0.2
      },
    ],
  }}
  options={options} 
  />
</div>
      </div>

    </div>
  );
}

