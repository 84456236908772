import React, { useState, useEffect, useRef  } from "react";
import { ReactPictureAnnotation, defaultShapeStyle } from "react-picture-annotation";
import kroki from "./kroki.png";
import axios from "axios";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../actions/dataActions.js";
import { toast } from 'react-toastify';
let mqttCli = require("mqtt");

let client;

const vertical = "bottom";
const horizontal = "right";

export const ImageMap = () => {
//    const notify = () => toast("Wow so easy!");


const reference = useRef(1)
const [deviceID, setDeviceID] = useState("");
const [mapDevices, setMapDevices] = useState(new Map());


function changeCoordinates(deviceID){

let holderObject = mapDevices.get(deviceID);
let holderArray = [...annotationState];
console.log("holderObject", holderObject );
console.log("holderArray", holderArray );
let sendingObject = {
locationID: null,
xcoordinate: 0,
ycoordinate: 0,
isOk: false,
}
for(let i=0; i<holderArray.length; i++){
if(deviceID === holderArray[i].id){
  sendingObject.locationID = holderArray[i].mark.locationID;
  sendingObject.xcoordinate = holderArray[i].mark.x;
  sendingObject.ycoordinate = holderArray[i].mark.y;
  sendingObject.isOk = true;
}
}


if(sendingObject.isOk){
const url = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/watmon/device_info/${sendingObject.locationID}`;

axios.patch(url, {
    xcoordinate: sendingObject.xcoordinate,
    ycoordinate: sendingObject.ycoordinate,
}, {
    headers: {
        'Content-Type': 'application/json',
    },
})
.then(response => {
    toast.success(`${deviceID} device coordinate is updated.`, {position: toast.POSITION.BOTTOM_RIGHT, autoClose:2500})
     
})
.catch(error => {
  toast.error( `${deviceID} device coordinate is not updated. error code is ${error.toString()}`, {position: toast.POSITION.BOTTOM_RIGHT, autoClose:2500})     
});
}

}



const updateMapDevices = (key, value) => {
setMapDevices(map => new Map(map.set(key, value)));
}


const dispatch = useDispatch();
const fetchData = (id,name,ip) => {
  dispatch(
    getData({
      ReceivedDeviceID: id,
      ReceivedDeviceName: name,
      ReceivedDeviceIP : ip,
      ReceivedLoading: true,
    })
  );
};




const [annotationState, setAnnotationState] = useState(
     [
    ]
  );

  
  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
  const environment = useSelector (state => state.dataOfEnvironment);
  useEffect(() => {
    //Runs only on the first render
    async function run() {
      let pathOfEnvironment = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/watmon/environment/?name=${environment.environmentName}`
      const responseOfEnvironment = await axios.get(pathOfEnvironment);
      const environmentList = responseOfEnvironment.data.results;
      let arrayOfDevice = [];
      for(let i=0; i< environmentList[0].sensors.length ; i++){
        let device = {
          id: environmentList[0].sensors[i].sensor_unique_identifier,
         mark: {
           x: environmentList[0].sensors[i].xcoordinate,
           y: environmentList[0].sensors[i].ycoordinate,
           width: 50,
           height: 40,
           type: "RECT", 
           fontBackground: "#f8f9fa",
           fontColor: "#212529",
           locationID: environmentList[0].sensors[i].id,
         },
         comment: `🔴	${environmentList[0].sensors[i].sensor_unique_identifier}`
       };
       arrayOfDevice.push(device);
       updateMapDevices(environmentList[0].sensors[i].sensor_unique_identifier, environmentList[0].sensors[i]);
      }

      setAnnotationState(arrayOfDevice)
    }
    run();
    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsMqtt = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };

  useEffect(() => {
    function startMqttSubscribe() {
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
      
      if (client.connected === false) {
        
      }
      client.subscribe(`watmon/app/link_info/+`, () => {
        //When a message arrives, print it to the console
        client.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
            changeAnnotationColor(dataAll.device, dataAll.status)
        });
      });


    }
    startMqttSubscribe()

  
  }, [annotationState]);

  useEffect(() => {
    return () => {
      
      client.end();
    };
  }, [annotationState]);

function changeAnnotationColor(deviceID, deviceState){
let findIndex = null;
let arrayOfDevice = [...annotationState];

for(let j=0; j<arrayOfDevice.length; j++){

if(deviceID === arrayOfDevice[j].id ){
  if(arrayOfDevice[j].comment !== changeAnnotationCommentSymbol(deviceID, deviceState)){
    findIndex = j; 
}
}
}

if(findIndex || findIndex === 0){
  //setAnnotationState(arrayOfDevice)
  const value = reference.current;
  // Update reference value:
  reference.current = value + 5;
  updateFieldChanged(findIndex, changeAnnotationCommentSymbol(deviceID, deviceState) )
}
}


function updateFieldChanged(index , comment)  {
  let newArr = [...annotationState]; // copying the old datas array
  // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
  newArr[index].comment = comment; // replace e.target.value with whatever you want to change it to

  setAnnotationState(newArr);
}


function changeAnnotationCommentSymbol(deviceID, deviceState){
  let commentText = ""
  if(deviceState === 0) commentText = "🔴 "
  else if(deviceState === 1) commentText = "🟢 "
  
  commentText = commentText + deviceID;
  return commentText
}


const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function onSelect(selectedId) {

  //  handleClick()
setDeviceID(selectedId);
  }
  const onChange = annotation => {
 //   setAnnotationState({ annotation });
 
    if (annotation) {
        handleClick()
    }
  };


useEffect(() => {
  const value = reference.current;
  // Update reference value:
  reference.current = value + 5;
}, [annotationState])


const [pageSize, setPageSize] = useState({
  width: window.innerWidth / 1.5,
  height: window.innerHeight / 1.5
});

useEffect(() => {
  const handleResize = () => {
    setPageSize({
      width: window.innerWidth / 1.5,
      height: window.innerHeight / 1.5
    });
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);



const action = (
  <React.Fragment>
    <Button color="primary" size="small" onClick={() => { changeCoordinates(deviceID) }}>
    Changes Coordinates
  </Button>

  <Button color="primary" size="small" onClick={() => { fetchData(deviceID, (mapDevices.get(deviceID) ?  mapDevices.get(deviceID).name : "FakeDeviceChosen"), 
    (mapDevices.get(deviceID) ? mapDevices.get(deviceID).ip : "11.11.11.11" ));}}>
    Access Device
  </Button>


  <IconButton sx={{ color: 'red' }}
    size="small"
    aria-label="close"
    onClick={handleClose}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
  </React.Fragment>
);


  return (
    <div>      
    <div style={{ marginTop: "25rem", marginLeft: "-30vw" }}>
      <ReactPictureAnnotation
      key={reference.current}
      inputElement={(value, onChange, onDelete) => (    
       console.log("ınputu aldık", value) 
      )}
        image= {kroki}
        onSelect={onSelect}
        onChange={onChange}
        width={pageSize.width}
        height={pageSize.height}
        annotationData={annotationState}
        annotationStyle={{
            ...defaultShapeStyle,
            padding: 2, // text padding
            fontSize: 15, // text font size
            fontColor: "#000000", // text font color
            fontBackground: "rgba(0,0,0,0.01)", // text background color
            shapeStrokeStyle: "#000000",
            transformerBackground: "rgba(0,0,0,0.01)",
            lineWidth: 0,
            shapeBackground: "rgba(0,0,0,0.5)", 
           
          }}
          scrollSpeed={0}
      />

    </div>   

    <div>
        {deviceID && (
      <Snackbar
      sx={{color:"white"}}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message= {`Chosen Device ID:  ${deviceID}`}
      action={action}
      anchorOrigin={{ vertical, horizontal }}    />
        )}

    </div> 
    </div>
  );
};

