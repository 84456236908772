
import React from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch } from "react-redux";
import { getData, setEnvironment } from "../actions/dataActions.js";
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import TimelineDot from "@mui/lab/TimelineDot";
import CircleIcon from "@mui/icons-material/Circle";
export default function PinInfo({setPopupOpening, building, InfoDeviceListOnline, deviceListPing}) {

  const dispatch = useDispatch();
  const fetchData = (id,name,ip) => {
    dispatch(
      getData({
        ReceivedDeviceID: id,
        ReceivedDeviceName: name,
        ReceivedDeviceIP : ip,
        ReceivedLoading: true
      })
    );
  };


   function selectThisEnvironment(id, envName, isEnv, deviceList) {
 dispatch(
  setEnvironment({
    environmentID : id, 
    environmentName: envName, 
    isEnvironmentSelected: isEnv,
    deviceList: deviceList
  })
 );
   };


    const handleClickAway = () => {
      setPopupOpening(false);
    };
  
    const styles = {
      position: 'absolute',
      top: 5,
      right: 0,
      left: 0,
      zIndex: 1,
      border: '1px solid',
      p: 1,
      bgcolor: 'background.paper',
      width: "300px", 
      maxWidth: 360,
    };

    function deviceInfoTip(deviceName, deviceId, deviceIp) {
      let deviceInfoText =
        `Device Name: ${deviceName} ` +
        `\nDeviceId: ${deviceId} ` +
        `\nDeviceIp: ${deviceIp}`;
      return deviceInfoText;
    }

  
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={styles}>
            <Tooltip
            title={"Click to select this environment"}
                      placement="top"
                                 >
              <Button variant="outlined" onClick={() => {selectThisEnvironment(building, building.name, true, building.sensors)} }><h4>{building.name}</h4></Button>  
              </Tooltip>
              <h5 style={{marginTop:"15px"}}>Device List:</h5>
              {building.sensors.map((device)=> (
                           <nav  key={device.ip}>
                           <List>
                             <ListItem disablePadding>
                               <ListItemButton sx={{border: "1px solid rgb(200,200,200)"}}
                                 onClick={() => {
                                   fetchData(device.sensor_unique_identifier, device.name, device.ip);
                                 }}
                               >

                                <Tooltip
                                   title={"if device is pingable, inner circle is green. if device is accessible, outline circle is green"}
                                   placement="bottom"
                                 >
                    <TimelineDot
                      sx={{ 
                       color: deviceListPing.get(device.sensor_unique_identifier) ? "green" : "red" ,
                       backgroundColor: InfoDeviceListOnline.get(device.sensor_unique_identifier) ? "green" : "red"}}
                     
                    >
                      <CircleIcon />
                    </TimelineDot>
                    </Tooltip>
                                 <Tooltip
                                   title={deviceInfoTip(device.sensor_unique_identifier, device.id, device.ip)}
                                   placement="bottom"
                                 >
                                   <ListItemText sx={{color:"blue", paddingLeft: "12px"}}primary={`${device.name}`} secondary={"DeviceID:\xa0" + device.sensor_unique_identifier} />
                                 </Tooltip>
                               </ListItemButton>
                             </ListItem>
                           </List>
                         </nav>


              ))}
            </Box>
      </ClickAwayListener>
    );
}
