export default function chartOptions(borderWidthValue, chartYAxisLabel){


    const options = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            borderWidth: borderWidthValue,
          },
        },
        scales: {
          x: {
            ticks: {
              color: "rgba( 0, 0, 0.2)",
            },
            grid: {
              color: "rgba(0, 0, 0, 0.2)",
            },
            title: {
              display: true,
              text: 'Time',
              font: {
                size: 17
              },
            },
          },
          y: {
            ticks: {
              color: "rgba(0, 0, 0, 1)",
            },
            grid: {
              drawBorder: true,
              drawTicks: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
            title: {
              display: true,
              text: chartYAxisLabel,
              font: {
                size: 17
              },
            },
          },
        },
        plugins: {
          autocolors: false,
          
        },
      };
    

return options;
}




export function marksOfChartLineBorder(){


    const marksOfChartLineBorder = [
        {
          value: 0.1,
          label: 'Min',
        },
        {
          value: 2.5,
          label: 'Default',
        },
        {
          value: 5,
          label: 'Max',
        },
      ];
      return marksOfChartLineBorder
    }
    
    
    export function getStyleOfSettingBlock(){
    const styleOfSettingBlock = {
      position: 'absolute',
      top:'relative',
      width: '20rem',
      zIndex: '3',
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.3)',
      background: 'rgba(255,255,255,0.85)',
      padding: '30px',
      paddingRight: '50px',
      borderRadius: '5px 50px 50px 50px',
    };
    return styleOfSettingBlock
    }
    