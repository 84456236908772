import React, {useState} from 'react'
import {createNewEnvironment} from "../../firebase"

function AddingEnvironment() {

    const [environmentName, setEnvironmentName] = useState("");
    const [environmentImageUrl, setEnvironmentImageUrl] = useState();


    const onChangePicture = e => {
        console.log('picture: ', environmentImageUrl);
        setEnvironmentImageUrl(...environmentImageUrl, e.target.files[0]);
    };


    async function addNewEnvironment(e){
    e.preventDefault();
    await createNewEnvironment({
        environmentName,
        environmentImageUrl
    })
    }


    
  return (
    <div>
        <h1>Add new environment with from</h1>
        <form onSubmit={addNewEnvironment}>
        <input type="text" value={environmentName} onChange={e => setEnvironmentName(e.target.value) } placeholder="Environment Name"/>
        <label>environment name</label>
        <input type="file" id="adminDealImg"  onChange={e => setEnvironmentImageUrl(e.target.files[0])}/>
        <button type="submit">ekle</button>
        </form>
    </div>
  )
}

export default AddingEnvironment