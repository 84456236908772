
export const getData =
({ ReceivedDeviceID, ReceivedDeviceName, ReceivedDeviceIP, ReceivedLoading }) =>
async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING",
    });

    let deviceID = "13ac520";
    let deviceName = "13ac520";
    let deviceIP = "172.16.8.184";
    let serverIPD = "172.16.8.184";
    let loading = false;

    deviceID = ReceivedDeviceID;
    deviceName = ReceivedDeviceName;
    deviceIP = ReceivedDeviceIP;
    loading = ReceivedLoading;

    dispatch({
      type: "SUCCESS",
      payload: {
        deviceID,
        deviceName,
        deviceIP,
        serverIPD,
        loading
      },
    });
  } catch (e) {
    dispatch({
      type: "REJECTED",
    });
  }
};

export const serverData =
  ({ serverIP, serverHttp, serverPORT, serverWs, serverMqttPort, serverMqttUser, serverMqttPassword, serverToken }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING-SERVER",
      });

      dispatch({
        type: "SUCCESS-SERVER",
        payload: {
          serverIP,
          serverHttp,
          serverPORT,
          serverWs,
          serverMqttPort,
          serverMqttUser,
          serverMqttPassword,
          serverToken,
        },
      });
    } catch (e) {
      dispatch({
        type: "REJECTED-SERVER",
      });
    }
  };

  

export const getTime = ({ startDate, endDate }) => async dispatch => {
  try {
    dispatch({
      type: "AWAITING-TIME"
    })

    let startDateInfo = Date.now();
    let endDateInfo = Date.now();

    startDateInfo = startDate;
    endDateInfo = endDate;
 
    dispatch({
      type: "SUCCESS-TIME",
      payload: {
        startDateInfo,
        endDateInfo
      }
    })
  } catch (e) {
    dispatch({
      type: "REJECTED-TIME",
    })
  }
}


export const setTimeLimitOfListeningDevice = ({ yellowTime, redTime }) => async dispatch => {
  try {
    dispatch({
      type: "AWAITING-TIMELIMITDEVICE"
    })

    let yellowTimeInfo = 120;
    let redTimeInfo = 135;

    yellowTimeInfo = yellowTime;
    redTimeInfo = redTime;
 
    dispatch({
      type: "SUCCESS-TIMELIMITDEVICE",
      payload: {
        yellowTimeInfo,
        redTimeInfo
      }
    })
  } catch (e) {
    dispatch({
      type: "REJECTED-TIMELIMITDEVICE",
    })
  }
}


export const themeLightOrDarkDecider = ({ themeDecider }) => async dispatch => {
  try {
    let themeDeciderCurrent = "light";
    themeDeciderCurrent = themeDecider;
    dispatch({
      type: "SUCCESS-THEMEDECIDER",
      payload: {
        themeDeciderCurrent
      }
    })
  } catch (e) {
    dispatch({
      type: "REJECTED-THEMEDECIDER",
    })
  }
}

export const setAnomaly = ({ receivedAnomalyID, receivedAnomalyData }) => async dispatch => {
  try {
    dispatch({
      type: "AWAITING-ANOMALY"
    })

    let anomalyID;
    let anomalyData;

    anomalyID = receivedAnomalyID;
    anomalyData = receivedAnomalyData;
 
    dispatch({
      type: "SUCCESS-ANOMALY",
      payload: {
        anomalyID,
        anomalyData
      }
    })
  } catch (e) {
    dispatch({
      type: "REJECTED-ANOMALY",
    })
  }
}


export const setEnvironment = ({environmentID, environmentName, isEnvironmentSelected, deviceList}) => async dispatch => {
try{
dispatch({
  type:"ENVIRONMENT-SELECTED",
  payload:{
    environmentID,
    environmentName,
    isEnvironmentSelected,
    deviceList
  }
})

}catch(e){
console.log("We encountered environment selection error", e);
}
}




export const ADD_ANOMALY = 'ADD_ANOMALY';
export const CLEAR_ANOMALIES = 'CLEAR_ANOMALIES';
export const REMOVE_ANOMALY = 'REMOVE_ANOMALY';
export const UPDATE_ANOMALY = 'UPDATE_ANOMALY';
export function addAnomaly(anomaly) {
  return { type: ADD_ANOMALY, payload: anomaly };
}

export function clearAnomalies() {
  return { type: CLEAR_ANOMALIES };
}


export function removeAnomaly(anomalyID) {
  return { type: REMOVE_ANOMALY, payload: anomalyID };
}



export function updateAnomaly(anomalyID, updatedAnomaly) {
  return { type: UPDATE_ANOMALY, payload: { anomalyID, updatedAnomaly } };
}
