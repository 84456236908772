import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import RealTimeChart from "./RealTimeChart.js";
let mqttCli = require("mqtt");

let client;
function AppMqtt({
  titles = ["free_disk_percentage", "cpu_load", "available_mem_percent"],
  url = "hardware",
  dataTypes = [
    "free_disk_percentage", "cpu_load", "available_mem_percent"
  ],
  MainHistory
}) {
  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);

  const [firstDataTypeArray, setFirstDataTypeArray] = useState([]);
  const [secondDataTypeArray, setSecondDataTypeArray] = useState([]);
  const [thirdDataTypeArray, setThirdDataTypeArray] = useState([]);
  const [fourthDataTypeArray, setFourthDataTypeArray] = useState([]);

  const [dataLabels, setDataLabels] = useState([]);
  const [deviceList, setDeviceList] = useState([])


  const optionsMqtt = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };
  const MAX_ENTRIES = 200;
  const DATA_LIMIT = 200;

  function updateArrayWithLimit(array, value) {
    const updatedArray = [...array, value];
    if (updatedArray.length > MAX_ENTRIES) {
        updatedArray.shift();  // en eski değeri diziden çıkar
    }
    return updatedArray;
}



  useEffect(() => {
    function startMqttSubscribe() {
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
     
      if (client.connected === false) {
        // Handle connection errors or actions if needed
      }

      const deviceList = [];
      const firstDataValues = [];
      const secondDataValues = [];
      const thirdDataValues = [];
      const fourthDataValues = [];
      const labels = [];

      client.subscribe(`${url}/+/`, () => {
        //When a message arrives, process it
        client.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
          
          if(!deviceList.includes(dataAll.sensor_unique_identifier)){
            deviceList.push(dataAll.sensor_unique_identifier);
            let newArray = new Array(labels.length).fill(null);
            firstDataValues.push([...newArray]);
            secondDataValues.push([...newArray]);
            thirdDataValues.push([...newArray]);
            fourthDataValues.push([...newArray]);
          }

          if (labels.length >= DATA_LIMIT) {
            labels.shift();
          }
          labels.push(moment(dataAll.timestamp).format("LT"));
          setDataLabels(prevLabels => {
            if (prevLabels.length >= DATA_LIMIT) {
              prevLabels.shift();
            }
            return [...prevLabels, moment(dataAll.timestamp).format("LT")];
          });

          // Handle firstDataType
          if (dataAll[dataTypes[0]] !== undefined) {
            const updatedValues = updateArrayWithLimit(firstDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)], dataAll[dataTypes[0]]);
            firstDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)] = updatedValues;

            for (let j = 0; j < firstDataValues.length; j++) {
              if (firstDataValues[j].length < labels.length) {
                firstDataValues[j] = updateArrayWithLimit(firstDataValues[j], null);
              }
            }
            setFirstDataTypeArray(firstDataValues);
            setDeviceList(deviceList);
          }

          // Handle secondDataType
          if (dataAll[dataTypes[1]] !== undefined) {
            const updatedValues = updateArrayWithLimit(secondDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)], dataAll[dataTypes[1]]);
            secondDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)] = updatedValues;

            for (let j = 0; j < secondDataValues.length; j++) {
              if (secondDataValues[j].length < labels.length) {
                secondDataValues[j] = updateArrayWithLimit(secondDataValues[j], null);
              }
            }
            setSecondDataTypeArray(secondDataValues);
          }

          // Handle thirdDataType
          if (dataAll[dataTypes[2]] !== undefined) {
            const updatedValues = updateArrayWithLimit(thirdDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)], dataAll[dataTypes[2]]);
            thirdDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)] = updatedValues;

            for (let j = 0; j < thirdDataValues.length; j++) {
              if (thirdDataValues[j].length < labels.length) {
                thirdDataValues[j] = updateArrayWithLimit(thirdDataValues[j], null);
              }
            }
            setThirdDataTypeArray(thirdDataValues);
          }

          // Handle fourthDataType
          if (dataAll[dataTypes[3]] !== undefined) {
            const updatedValues = updateArrayWithLimit(fourthDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)], dataAll[dataTypes[3]]);
            fourthDataValues[deviceList.indexOf(dataAll.sensor_unique_identifier)] = updatedValues;

            for (let j = 0; j < fourthDataValues.length; j++) {
              if (fourthDataValues[j].length < labels.length) {
                fourthDataValues[j] = updateArrayWithLimit(fourthDataValues[j], null);
              }
            }
            setFourthDataTypeArray(fourthDataValues);
          }
        });
      });

      return () => client.end();  // Cleanup the MQTT client on component unmount
    }
    startMqttSubscribe();
}, []);

  useEffect(() => {
    return () => {
      
      client.end();
    };
  }, []);

  function decideToArrayValue(index) {
    if (index === 0) return firstDataTypeArray;
    else if (index === 1) return secondDataTypeArray;
    else if (index === 2) return thirdDataTypeArray;
    else if (index === 3) return fourthDataTypeArray;

 
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {titles.map((title, index) => (
          <div className={"chart-wrapperOneBigChart"}>
          
            <div
              className="topbarIconContainer"
              style={{
                float: "left",
                marginLeft: "2rem",
                marginTop: "-0.1rem",
                marginBottom: "-2.2rem",
              }}
            >
          
            </div>
            <div
              style={{
                float: "right",
                marginTop: "-0.1rem",
                marginBottom: "-2.2rem",
              }}
            >
             {index === 0 ? (MainHistory) : null} 
            </div>
            <RealTimeChart
              dataValues={decideToArrayValue(index)}
              dataLabels={dataLabels}
              titles={titles}
              deviceList = {deviceList}
              index={index}
            />
          </div>
        ))}
      </div>
    </>
  );
}
export default AppMqtt;
