import React from 'react';
import { useSelector } from "react-redux";
import { clearAnomalies, removeAnomaly } from '../../../actions/dataActions';
import { useDispatch } from "react-redux";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


export default function AnomalyList() {
  const anomalies = useSelector((dataOfDevice) => dataOfDevice.anomalies);
  const dispatch = useDispatch();
  function clearArray(){
    
    dispatch(clearAnomalies())
    
  }

  function deleteAnomaly(AnomalyId){
    dispatch(removeAnomaly(AnomalyId));
  }
  return (
    <div>

      <table class="content-table">
      <thead>
        <tr>
          <th>AnomalyID</th>
          <th>Topic</th>
          <th>Target Field</th>
          <th>Started Time</th>
          <th>Ended Time</th>
          <th>sensorID</th>
          <th>confidence</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
      {anomalies.map((anomaly, index)=> (
        <tr key={index}>
          
          <td>{anomaly.anomalyID}</td>
          <td>{anomaly.topic}</td>
          <td>{anomaly.target_field}</td>
          <td>{anomaly.anomalyStartedTime}</td>
          <td>{anomaly.anomalyEndedTime}</td>
          <td>{anomaly.sensorID}</td>
          <td>{anomaly.confidence}</td>
          <td>
          <IconButton aria-label="delete" size="large" color="error"  onClick={() => deleteAnomaly(anomaly.anomalyID)}>
        <DeleteIcon />
      </IconButton>
          </td>
        </tr>
          ))}
          <tr style={{backgroundColor: "rgba(255,0,0,0.3)"}}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Delete</td>
          <td>All</td>
          <td>
          <IconButton aria-label="delete" size="large" color="error"  onClick={() => clearArray()}>
        <DeleteIcon />
      </IconButton>
          </td>
          </tr>

      </tbody>
    </table>

    
    </div>
  );
}

