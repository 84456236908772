import React from 'react'
import './deviceInfo.css';


export default function DeviceInfo(){




    return (
        <div className="containerDeviceInfo">
            <div className="card">
                <div className="content">
                    <h2>WATMON</h2>
                     <p>Device Info: 256 MB SDRAM memory. Single 2.0 USB connector. Dual Core Video Core IV Multimedia coprocessor.</p>
                     <p>
                     </p>
                     <a href="#">Read More</a>

                </div>
            </div>

            <div className="card">
                <div className="content">
                    <h2>Location</h2>
                     <p>Venit Lab no:01,building 03, Orhantepe Mahallesi, Turgut Özal Bulvarı, No: 21 Dragos, 34865 Kartal/İstanbul</p>
                     <p>
                     </p>
                     <a href="#">Go to Address</a>
                </div>
            </div>


            <div className="card">
                <div className="content">
                    <h2>Status</h2>
                     <p>Connection : Good</p>
                     <p>Damage record : No record</p>
                     <p>Date of registration : 24.01.2020</p>
                     <p>Score : 8/10</p>
                     <p>lorem1213d lorem book pencil medicine
                     </p>
                     <div className="divGreen" style={{marginLeft: '6.5rem', marginTop: '0.7rem'}}></div>
                     <a href="#">Read More</a>
                </div>
            </div>
                </div>
            )
}
