import React,{ useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import { serverData } from '../../../actions/dataActions'

export default function CustomForm() {

  const dispatch = useDispatch();
  const dataServer = useSelector (dataServer  => dataServer.dataOfServer);   

  const [stringServerIP, setStringServerIP] = useState(`${dataServer.serverIP}`);
  const [stringServerHttp, setStringServerHttp] = useState(`${dataServer.serverHttp}`)
  const [stringServerPort, setStringServerPort] = useState(`${dataServer.serverPORT}`);

  const [stringServerWs, setStringServerWs ] = useState(`${dataServer.serverWs}`);
  const [stringServerMqttPort, setStringServerMqttPort] = useState(`${dataServer.serverMqttPort}`);
  const [stringServerMqttUser, setStringServerMqttUser] = useState(`${dataServer.serverMqttUser}`);
  const [stringServerMqttPassword, setStringServerMqttPassword] = useState(`${dataServer.serverMqttPassword}`);
  const [stringServerToken, setStringServerToken] = useState(`${dataServer.serverToken}`);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget)
    dispatch(serverData  ({
      serverIP: data.get('ipAddress'),
      serverHttp: data.get('httpType'),
      serverPORT: data.get('httpPort'),
      serverWs: data.get('serverWs'),
      serverMqttPort: data.get('mqttPort'),
      serverMqttUser: data.get('username'),
      serverMqttPassword: data.get('password'),
      serverToken: data.get('token'),

  }))

  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        mt:10
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%'}}>
        <Typography variant="h4" component="h1" gutterBottom>
          Server Settings
        </Typography>
        <TextField
          name="ipAddress"
          label="IP Address"
          variant="outlined"
          value = {stringServerIP} 
          onChange={(e) => setStringServerIP(e.target.value) }
          fullWidth
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
            name="httpType"
            label="HTTP Type"
            variant="outlined"
            sx={{ width: '33%' }}
            value={stringServerHttp}
            onChange={(e) => setStringServerHttp(e.target.value) }
          />

          <TextField
            name="httpPort"
            label="HTTP Port"
            variant="outlined"
            sx={{ width: '33%' }}
            value={stringServerPort}
            onChange={(e) => setStringServerPort(e.target.value) }
          />

<TextField
            name="serverWs"
            label="Mqtt Connection Type"
            variant="outlined"
            sx={{ width: '33%' }}
            value={stringServerWs}
            onChange={(e) => setStringServerWs(e.target.value) }
          />

          <TextField
            name="mqttPort"
            label="MQTT Port"
            variant="outlined"
            sx={{ width: '33%' }}
            value={stringServerMqttPort}
            onChange={(e) => setStringServerMqttPort(e.target.value) }
          />
        </Box>
        <TextField
          name="username"
          label="Username"
          variant="outlined"
          fullWidth
          value={stringServerMqttUser}
          onChange={(e) => setStringServerMqttUser(e.target.value) }
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={stringServerMqttPassword}
          onChange={(e) => setStringServerMqttPassword(e.target.value) }
        />
        <TextField
          name="token"
          label="Token"
          variant="outlined"
          fullWidth
          value={stringServerToken}
          onChange={(e) => setStringServerToken(e.target.value) }
        />
        <Button type="submit" variant="contained" sx={{mt:3}}>Change Server Settings</Button>
      </Box>
    </Box>
  );
}