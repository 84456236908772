

export default function chartOptions(borderWidthValue, maxValue, minValue,standardDeviation, YaxisLabel, chartNoConnectionAreas){


let ObjectMinMaxDeviation ={
  line1: {
    type: "line",
    yMin: maxValue,
    yMax: maxValue,
    borderColor: "#FF5733",
    borderWidth: 2,
    label: {
      rotation: "auto",
      backgroundColor: "red",
      borderColor: "black",
      borderRadius: 10,
      borderWidth: 3,
      borderDash: [6, 6],
      content: `max:${parseFloat(maxValue).toFixed(3)}`,
      enabled: true,
      position: "start",
    },
  },
  line2: {
    type: "line",
    yMin: minValue,
    yMax: minValue,
    borderColor: "#FF5733",
    borderWidth: 2,
    label: {
      rotation: "auto",
      backgroundColor: "red",
      borderColor: "black",
      borderRadius: 5,
      borderWidth: 2,
      borderDash: [6, 6],
      content: `Min: ${parseFloat(minValue).toFixed(3)}`,
      enabled: true,
      position: "start",
    },
  },
line5: {
    type: "line",
    yMin: minValue,
    yMax: minValue,
    borderColor: "#FF5733",
    borderWidth: 2,
    label: {
      rotation: "auto",
      backgroundColor: "red",
      borderColor: "red",
      borderRadius: 5,
      borderWidth: 2,
      borderDash: [6, 6],
      content: `Standard Deviation:${standardDeviation.toFixed(3)}`,
      enabled: true,
      position: "end",
    },
    

  },

  newNoConnectionArea : {
    type: 'box',
    yMin: 0,
    yMax: 50000,
    xMin: 70,
    xMax: 90,
    backgroundColor: 'rgba(255, 99, 132, 0.25)',
    label: {
      type: 'label',
      backgroundColor: 'rgba(245,245,245)',
      content: ['This is my text', 'This is my text, second line'],
    }
   }
  
}

let newAnnutions = Object.assign({}, ObjectMinMaxDeviation, chartNoConnectionAreas);
  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio:false,
    elements: {
      line: {
        borderWidth: borderWidthValue,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "rgba( 255, 255, 255, 1)",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)",
        },
        title: {
          display: true,
          text: 'Time',
          color: "rgba( 255, 255, 255, 1)",
          font: {
            size: 24
          },
        },
      },
      y: {
        ticks: {
          color: "rgba(255, 255, 255, 1)",
        },
        grid: {
          drawBorder: true,
          drawTicks: true,
          color: "rgba(255, 255, 255, 0.35)",
        },
        title: {
          display: true,
          text: `${YaxisLabel}`,
          color: "rgba( 255, 255, 255, 1)",
          font: {
            size: 24
          },
        },
      },
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: newAnnutions,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          mode: "x",
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: "xy",
          speed: 100,
        },
      },
    },
  };

  return options;
}



export function marksOfChartLineBorder(){


const marksOfChartLineBorder = [
    {
      value: 0.1,
      label: 'Min',
    },
    {
      value: 2.5,
      label: 'Default',
    },
    {
      value: 5,
      label: 'Max',
    },
  ];
  return marksOfChartLineBorder
}


export function getStyleOfSettingBlock(){
const styleOfSettingBlock = {
  position: 'absolute',
  top:'relative',
  width: '20rem',
  zIndex: '3',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.3)',
  background: 'rgba(255,255,255,0.85)',
  padding: '30px',
  paddingRight: '50px',
  borderRadius: '5px 50px 50px 50px',
};
return styleOfSettingBlock
}
