import React, { useEffect, useState} from "react";
import { Line, Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import Slider from '@mui/material/Slider';
import options, {marksOfChartLineBorder, getStyleOfSettingBlock} from './ChartComponentOptions'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

Chart.register(annotationPlugin);
Chart.register(zoomPlugin);
// min and max slider in isimleri yanıltmasın, min en sonda olan, max ise geçmişe gidebilmemize yardım eder.
const ChartComponent = ({
  chartMaxData,
  chartMinData,
  chartDataHttpRttMean,
  chartDataHttpRtt,
  chartNetworkHttpLabel,
  chartStandardDeviation,
  YaxisLabel,
}) => {


  useEffect(() => {

  if(chartDataHttpRtt.length > 2500)
  {
    setBorderWidthValueMean(0.01)
    setBorderLineShow(false)
    setChartPointRadiusValueMean(1)
  }else if(chartDataHttpRtt.length > 200){
    setBorderLineShow(true)
    setBorderWidthValueMean(0.5)
    setBorderWidthValue(1)
    setChartPointRadiusValueMean(1)
  }
  
  
  else{
    setBorderWidthValueMean(2.5)
    setBorderLineShow(true)
    setBorderWidthValue(2.5)
    setChartPointRadiusValueMean(2.5)
  }
    
  }, [chartDataHttpRtt]);

  const [borderWidthValueMean, setBorderWidthValueMean] = useState(2.5);
  const [borderWidthValue, setBorderWidthValue] = useState(2.5);
  const [chartPointRadiusValue, setChartPointRadiusValue] = useState(2.5);
  const [chartPointRadiusValueMean, setChartPointRadiusValueMean] = useState(2.5);

  const [borderLineShow, setBorderLineShow] = useState(true);
  const [backGroundShow, setBackGroundShow] = useState(false);

  function changeChartRangeLineDrawingBorder(event,e) {
    setBorderWidthValue(e);
  }

  function changeChartRangeLineDrawingBorderMean(event, e) {
    setBorderWidthValueMean(e);
  }

  function changeChartRangePointRadius(event, e) {
    setChartPointRadiusValue(e);
  }

  function changeChartRangePointRadiusMean(event,e) {
    setChartPointRadiusValueMean(e);
  }

  function disableChartLine(e) {
    setBorderLineShow(e.target.checked);
  }

  function fillChartBackGround(e) {
    setBackGroundShow(e.target.checked);
  }


  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  
  const handleClickAway = () => {
    setOpen(false);
  };


  return (
<div>

<ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{position:'absolute'}}>
<div style={{}}>
      <IconButton color="info" size="small"  onClick={handleClick} >
        <SettingsIcon color="info"/>
        </IconButton>
        </div>
        {open ? (
          <Box sx={getStyleOfSettingBlock}>
            <div>
                 <h4 style={{textAlign:'center'}}>Settings</h4>

       

              <label>Main Border Width Range</label>
              <Slider
  value={borderWidthValue} 
  onChange={changeChartRangeLineDrawingBorder}
  step={0.3}
  marks = { marksOfChartLineBorder()}
  min={0.1}
  max={5}
  valueLabelDisplay="auto"
  size="small"
/>
              <label>Mean Border Width Range</label>
              <Slider
  value={borderWidthValueMean} 
  onChange={changeChartRangeLineDrawingBorderMean}
  step={0.3}
  marks = { marksOfChartLineBorder()}
  min={0.1}
  max={5}
  valueLabelDisplay="auto"
  color="error"
  size="small"
/>
              <label>Point Radius Range</label>
              <Slider
                min={0.1}
                max={5}
                step={0.2}
                value={chartPointRadiusValue}
                valueLabelDisplay="auto"
                marks = { marksOfChartLineBorder() }
                onChange={changeChartRangePointRadius}
                size="small"
           
                />
              <label>Mean Point Radius Range</label>
              <Slider
                min={0.1}
                max={5}
                step={0.2}
                value={chartPointRadiusValueMean}
                marks = { marksOfChartLineBorder() }
                valueLabelDisplay="auto"
                onChange={changeChartRangePointRadiusMean}
                color="error"
                size="small"
                />

<input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {borderLineShow}
                onClick={(e) => disableChartLine(e)}
              ></input>
                    <label style={{width: "auto", marginRight:"110px"}} >Show ChartLine</label>
            
              <input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {backGroundShow}
                onClick={(e) => fillChartBackGround(e)}
              ></input>
                <label>Fill ChartBackGround</label>
            </div>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
      
        <Line
          className={"chart-line-mqqt-link"}         // chartSize      "chart-lineOneChart"  "chart-line-mqqt-link"
          style={{
            width: "700px",
            height:"700px"
          }}
          data={{
            labels: chartNetworkHttpLabel,
            datasets: [
              {
                label: "Mean",
                data: chartDataHttpRttMean,
                borderColor: "rgb(34, 233, 226)",
                color:"rgb(34, 233, 226)",
                backgroundColor:"rgba(34, 233, 226,0.3)",
                showLine: true,
                pointRadius: chartPointRadiusValueMean,
                hoverRadius: 7,
                hoverBorderWidth: 7,
                borderWidth: borderWidthValueMean,
              },
              {
                label: "Value",
                data: chartDataHttpRtt,
                borderColor: "rgb(11, 84, 223)",
                showLine: borderLineShow,
                pointRadius: chartPointRadiusValue,
                backgroundColor: "rgba(11, 84, 223,0.3)",
                fill: backGroundShow,
                hoverRadius: 7,
                hoverBorderWidth: 7,
              },
            ],
          }}
          options={options(borderWidthValue, chartMaxData, chartMinData,chartStandardDeviation, YaxisLabel )}
        />
      
      </div>
  );
};
export default ChartComponent;
