import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import TimelineDot from "@mui/lab/TimelineDot";
import CircleIcon from "@mui/icons-material/Circle";
import Badge from '@mui/material/Badge';

let mqttCli = require("mqtt");
let client;

function WarningLight({url=''}) {
  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
  const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);
    const [count, setCount] = useState(3);
    const [time, setTime] = useState("");
    const optionsMqtt = {
      username: dataOfServer.serverMqttUser,
      password: dataOfServer.serverMqttPassword,
    };

  useEffect(() => {
    function startMqttSubscribe() {
      const originalString = url;
      const newString = originalString.substring(0, 7) + "app" + originalString.substring(6);
      
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
      
      if (client.connected === false) {
        
      }
      client.subscribe(
        `${newString}/${dataOfDevice.deviceID}`,
        () => {
          //When a message arrives, print it to the console
          
          client.on("message", (topic, message, packet) => {
            
            const string = new TextDecoder().decode(message);
            const object = JSON.parse(string);
           
            setCount(object.status);

            const timestampInSeconds = object.timestamp;
            const timestampInMilliseconds = timestampInSeconds * 1000;
            
            const date = new Date(timestampInMilliseconds);
            const dateISOString = date.toISOString();
            
            // Remove 'T', 'Z', and milliseconds
            const modifiedISOString = dateISOString.replace('T', ' ').split('.')[0];
            console.log(modifiedISOString); 
            
           
            
            setTime(modifiedISOString)     
          });
        }
      );

      return () => {
        client.end();
        setCount(null);
      };
    }
    startMqttSubscribe();
  }, []);


  return (
    
    
    <div
    className="topbarIconContainer"
    style={{ float: "right", marginLeft: "-100px", paddingRight:"8px", marginTop:"-3px"}}
  >
 <div>
 {count !== 3  ?
 <div>
 <Tooltip title={`last update on: ${time}`} placement="bottom">
   <Badge badgeContent={`${dataOfDevice.deviceID}`} size="small" sx={{color:"rgba(0,0,0,0.01)"}}  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}>
   
<TimelineDot
sx={{ 
 color: count ? "green" : "red" ,
 backgroundColor: count ? "green" : "red" ,
 width:16, height:16
}}
size="small"
>
<CircleIcon  size="small"/>
</TimelineDot>
</Badge>
</Tooltip>
</div>
  :null}
 </div> 
  </div>

  )
}

export default WarningLight



/*
<Tooltip title={`last state received time is ${time}`} placement="bottom">
<div className="divRed">.</div></Tooltip>
<TimelineDot
*/