import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { getData, setEnvironment } from "../../../actions/dataActions.js";
import image from "../../../map/ImageMap/kroki.png";
import raspberryPi from "../../../logo-and-photo/images/RaspberryPi.png";
import selectEnvironment from "../../../logo-and-photo/images/select-an-environment.png";
import selectDevice from "../../../logo-and-photo/images/select-device.png";

export default function DeviceAndEnv() {
    const dispatch = useDispatch();


  const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);
  const dataOfEnvironment = useSelector(
    (dataOfEnvironment) => dataOfEnvironment.dataOfEnvironment
  );



  function removeDevice(id,name,ip){       
    const fetchData = (id,name,ip ) => {
      dispatch(
        getData({
          ReceivedDeviceID: id,
          ReceivedDeviceName: name,
          ReceivedDeviceIP : ip,
          ReceivedLoading: false,
        })
      );
    };
  fetchData(id,name,ip)
}




  function removeEnvironment(){
    function selectThisEnvironment(id, envName, isEnv, deviceList) {
        dispatch(
         setEnvironment({
           environmentID : id, 
           environmentName: envName, 
           isEnvironmentSelected: isEnv,
           deviceList: deviceList
         })
        );
          };
          selectThisEnvironment("xxx","xxx",false, ["xxx"])
}

  return (
    <>
      <Card sx={{ maxWidth: 345, marginTop: "5%", marginRight: "5%" }}>
        <CardMedia
          sx={{ height: 140 }}
          image={
            dataOfEnvironment.isEnvironmentSelected ? image : selectEnvironment
          }
          title="Environment"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {dataOfEnvironment.isEnvironmentSelected
              ? dataOfEnvironment.environmentName
              : "Please Select Environment From the Map"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dataOfEnvironment.isEnvironmentSelected
              ? `Environment id is${dataOfEnvironment.environmentID} and location is in the Marmara University.`
              : "Please Select Environment From the Map."}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
            disabled={!dataOfEnvironment.isEnvironmentSelected}
            onClick={() => removeEnvironment()}
          >
            Remove Environment
          </Button>
        </CardActions>
      </Card>
      <Card sx={{ maxWidth: 345, marginTop: "5%" }}>
        <CardMedia
          sx={{ height: 140 }}
          image={dataOfDevice.loading ? raspberryPi : selectDevice}
          title="Device"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {dataOfDevice.loading
              ? dataOfDevice.deviceName
              : "Please Select a Device "}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dataOfDevice.loading
              ? `Device ID is ${dataOfDevice.deviceID} and device location is N/A, Hardware information: 
          256 MB SDRAM memory. Single 2.0 USB connector. Dual Core Video Core IV Multimedia coprocessor. Device Registiration: 2021.12.02`
              : "Please Select a Device. You can select from the map or you can select from schema if you environment already selected."}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
            disabled={!dataOfDevice.loading}
            onClick={() => removeDevice("", "", "")}
          >
            Remove Device
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
