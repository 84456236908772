import * as React from 'react';
import {Marker} from 'react-map-gl';
import Pin from './Pin'

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function Pins(props) {
  const {data, onClick} = props;

  return data.map((building, index) => (
    <Marker key={`marker-${index}`} longitude={building.longitude} latitude={building.latitude} 
    offsetLeft={-30} // deneme değeri
    offsetTop={-55}  // deneme değeri
    >
      <Pin index={index} deviceList={building.sensors} onClick={onClick} building={building}/>
    </Marker>
  ));
}



export default React.memo(Pins);
