/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SensorsIcon from '@mui/icons-material/Sensors';

import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import DevicePopUp from './DevicePopUp'
import DevicePopUpGauges from "./DevicePopUpGauges";
import Tooltip from '@mui/material/Tooltip';
let mqttCli = require("mqtt");

let client;

function Device() {

    const dataOfDevice = useSelector (dataOfDevice  => dataOfDevice.dataOfDevice)
    const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
    const deviceReachable = null;
    const [isDeviceReachable, setIsDeviceReachable] = useState(deviceReachable)

  

    const notifyErrorOfDeviceConnection = () => {
      toast.error( `${dataOfDevice.deviceName} device is not accessible.`, {position: toast.POSITION.BOTTOM_RIGHT, autoClose:1500})       
    }

    const notifySuccessOfDeviceConnection = () => {
        toast.success(`${dataOfDevice.deviceName} device is accessible.`, {position: toast.POSITION.BOTTOM_RIGHT, autoClose:1500})
      }
      const notifyDeviceChanges = () => {
        toast.info(`${dataOfDevice.deviceName} waiting to reponse.`, {position: toast.POSITION.BOTTOM_RIGHT, autoClose:1500})
      }

          // Now, you can use certificateBuffer in your MQTT configuration
    const optionsMqtt = {
      //      protocol: 'mqtts',
      username: dataOfServer.serverMqttUser,
      password: dataOfServer.serverMqttPassword,
      //     ca: [b]
       // Provide the certificate as an array of Buffers
    };

      useEffect(() => {
        function startMqttSubscribe() {
          if(client){
            client.end();
          }
         
          notifyDeviceChanges();
          setIsDeviceReachable(null);
          client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
          
          if (client.connected === false) {
           
          }
          client.subscribe(`watmon/app/link_info/${dataOfDevice.deviceID}`, () => {           
            //When a message arrives, print it to the console
            client.on("message", (topic, message, packet) => {
              let dataAll = JSON.parse(message);
              console.log("dataAll: ", dataAll)
              if(dataAll.status === 1)
              setIsDeviceReachable(true)
              if(dataAll.status === 0)
              setIsDeviceReachable(false)

            });
          });
    
          return client;
        }
        
        if(dataOfDevice.deviceID !== ""){
          if(dataOfDevice.deviceID !== ""){
            startMqttSubscribe();
        }
         }
      
        
      }, [dataOfDevice.deviceID]);

      
      useEffect(() => {
        if(dataOfDevice.deviceID !== ""){
           if(isDeviceReachable == null){
            
           }
            else if(isDeviceReachable)
            notifySuccessOfDeviceConnection()
            else{
              notifyErrorOfDeviceConnection()
            }
           
        }
      }, [isDeviceReachable]);













      const [open, setOpen] = useState(false);

      const handleClick = () => {
     
        setOpen(priv => !priv);


      };
      
      const handleClickAway = () => {
        setOpen(false);
      };
      function getStyleOfSettingBlock(){
        const styleOfSettingBlock = {
          position: 'absolute',
          top:'relative',
          width: '20rem',
          zIndex: '3',
          border: '1px solid rgba(0, 0, 0, 0.04)',
          boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.3)',
          bgcolor: 'background.paper',
          padding: '30px',
          borderRadius: '5px 50px 50px 50px',
        };
        return styleOfSettingBlock
        }
  return (
    <div>
 <ClickAwayListener onClickAway={handleClickAway}>
  <div style={{
    placeItems: "center",
    alignContent: "center",
    alignItems:"center",
    verticalAlign: "middle",
    textAlign: "center"
  }}>
{dataOfDevice.loading && (  
   <>
<Button onClick={handleClick}>
        {isDeviceReachable &&   <h4 style={{color:'#01D964', marginRight:'7px' }}>{dataOfDevice.deviceID}</h4>  }
        {(!isDeviceReachable)  &&   <h4 style={{color:'#FF0000', marginRight:'7px'}}>{dataOfDevice.deviceID}</h4>  }
        </Button>       
        {(isDeviceReachable) &&                  
         <Tooltip title={"the device is accessible"} placement="bottom">
        <SensorsIcon 
                    fontSize="large"
                    sx={{ color: `#01D964`,}}
                  />
                  </Tooltip>
                  }
                 {(!isDeviceReachable)  &&  
                 <Tooltip title={"the device is inaccessible "} placement="bottom">
                 <SensorsOffIcon
                    fontSize="large"
                    sx={{ color: `#FF0000`,  }}
                  />
                  </Tooltip>
                  }
{(isDeviceReachable)  &&  <DevicePopUpGauges/>}   
        {open ? (
          <Box sx={getStyleOfSettingBlock}>         
            <DevicePopUp/>   
          </Box>
        ) : null}
                 </>
                 )}
                 </div>
                 </ClickAwayListener>          
    </div>
  )
}

export default Device