import React from 'react'
import { Line, Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import MainMqtt from "./mqtt/MainMqqt"
Chart.register(annotationPlugin);
Chart.register(zoomPlugin);
function MainChart() {

    const labels = [];
    const firstData = [];
    const secondData = [];

    const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
    for(let i=0; i<1000; i++){
    labels.push(`Date: ${i}`);
    firstData.push((Math.random() > 0.3 ? null : (Math.random() * 5 + 15)))
    secondData.push((Math.random() > 0.5 ? null : (Math.random() * 5)))
    }
  return (
    <>
<Line
            style={{
                height: `70vh`,
                width: "70vw",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
              }}          
          data={{
            labels: labels,
            datasets: [
              {
                label: "Line one",
                data: firstData,
                borderColor: `rgba(255,200,100, 1)`,
                backGroundColor: function(context, options) {
                  const color = "rgba(0,255,0)" // resolve the value of another scriptable option: 'red', 'blue' or 'green'
                  return color;
                },
                showLine: true,
                pointRadius: 2,
                segment: {
                  borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)'),
                  borderDash: ctx => skipped(ctx, [6, 6]),
                },
              },
              {
                label: "Line Two",
                data: secondData,
                borderColor:"rgb(120, 80, 0, 0.8)",
                backgroundColor: "rgb(50, 216, 190, 0.3)",
                showLine: true,
                pointRadius: 2,
                segment: {
                  borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)'),
                  borderDash: ctx => skipped(ctx, [6, 6]),
                },
              }
            ],
          }}

          options={{
            plugins: {
              title: {
                display: true,
                text: "PROTOCOL",
              },
              autocolors: false,
              zoom: {
                zoom: {
                  wheel: {
                    enabled: true, // SET SCROOL ZOOM TO TRUE
                  },
                  mode: "x",
                  speed: 100,
                },
                pan: {
                  enabled: true,
                  mode: "xy",
                  speed: 100,
                },
              },
            },
                scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
              color: "rgba(0, 0, 0, 1)",
            },
            grid: {
              drawBorder: true,
              drawTicks: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
            title: {
              display: true,
              text: "Line Title",
              font: {
                size: 17
              },
            },
          },
        
        },
}}
        />

    </>
  )
}


function MultiCihazChart() {

  const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;


  return (
    <>
    <Line
            style={{
                height: `70vh`,
                width: "70vw",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
              }}          
          data={{
            labels: ["date1","date2","date3","date4","date5","date6","date7"],
            datasets: [
              {
              label: 'My First Dataset',
              data: [65, 59, NaN, 48, 56, 57, 40],
              borderColor: 'rgb(75, 192, 192)',
              segment: {
                borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)'),
                borderDash: ctx => skipped(ctx, [6, 6]),
              },
              spanGaps: true
            }]
          }}

          options={{
            fill: false,
            interaction: {
              intersect: false
            },
            radius: 0,
}}
        />
    
    
    </>
  )
}



function mainMqtt({titles, url, dataTypes}) {
  return (
    <MainMqtt
    titles = {titles}
    url = {url}
    dataTypes = {dataTypes} 
    />
  )
}



export default mainMqtt

