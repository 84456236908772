import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {useSelector } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";




export default function BasicButtonGroup() {
    const location = useLocation();
    const history = useHistory();
    
    
    const dataOfDevice = useSelector((dataOfDevice => dataOfDevice.dataOfDevice));
    const dataOfEnvironment = useSelector((dataOfEnvironment => dataOfEnvironment.dataOfEnvironment));

    const[selectedButton, setSelectedButton ] = useState(1);
    function ButtonSelected(selectedType){
        setSelectedButton(selectedType)
    }


        useEffect(() => {
          let nextPath = location.pathname.toString().replace('/', '');
          let nextEnvPath = "";
          if( (nextPath === "hardware") || (nextPath === "emulator")  || (nextPath === "link-info")){
           if(selectedButton === 2){
            nextEnvPath = "/environment-" + nextPath
            history.push(nextEnvPath);
           } 
          }
        }, [location.pathname])
        













   function deviceToEnvironment() {
    ButtonSelected(2)
    let devicePath = location.pathname.toString().replace('/', '');
    let devicePathToEnvironment = ""
    if(devicePath === "hardware") devicePathToEnvironment = "/environment-" + devicePath
    if(devicePath === "emulator") devicePathToEnvironment = "/environment-" + devicePath
    if(devicePath === "link-info") devicePathToEnvironment = "/environment-" + devicePath
    if(devicePathToEnvironment.length > 1){
        history.push(devicePathToEnvironment);
    }
}

function environmentToDevice() {
    ButtonSelected(1)
    let devicePath = location.pathname.toString().replace('/', '');
    let devicePathToEnvironment = ""
    if(devicePath === "environment-hardware") devicePathToEnvironment = "hardware"
    if(devicePath === "environment-emulator") devicePathToEnvironment = "emulator"
    if(devicePath === "environment-link-info") devicePathToEnvironment = "link-info"
    if(devicePathToEnvironment.length > 1){
        history.push(devicePathToEnvironment);
    }
    
}

  return (
    <>
   
    <ButtonGroup size="small" aria-label="small button group">
      <Button color="warning" variant= {selectedButton === 1 ? "contained" : "outlined"} onClick={() => environmentToDevice()} disabled={!dataOfDevice.loading}>
        Device
        
          </Button>

    
      <Button color="warning"  variant= {selectedButton === 2 ? "contained" : "outlined"} onClick={() => deviceToEnvironment()} disabled={!dataOfEnvironment.isEnvironmentSelected}>
        Environment
        
        </Button>
    </ButtonGroup>
     
      </>
  );
}