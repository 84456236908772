import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import ChartComponent from "./ChartComponentBins";
import Box from "@mui/material/Box";
import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ChartHistorySlider from "./ChartHistorySlider";
export default function MainHistory({
  titles = ["free_disk_percentage", "cpu_load", "available_mem_percent", "free_disk_percentage"],
  url = "hardware",
  dataTypes = [
    "free_disk_percentage",
    "cpu_load",
    "available_mem_percent",
    "free_disk_percentage"
  ],

}) {


    const dataOfTime = useSelector((dataOfTime) => dataOfTime.dataOfTime);
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [maxCount, setMaxCount] = useState(0);
    const [startDateOfChart, setStartDateOfChart] = useState(" ");
    const [endDateOfChart, setEndDateOfChart] = useState(" ");
    const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer); 
    const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);
  

    const holderDataLabels = [];

    const holderFirstDataType = [];
    const holderSecondDataType = [];
    const holderThirdDataType = [];
    const holderFourthDataType = [];

    const [firstDataTypeArray, setFirstDataTypeArray] = useState([]);
    const [secondDataTypeArray, setSecondDataTypeArray] = useState([]);
    const [thirdDataTypeArray, setThirdDataTypeArray] = useState([]);
    const [fourthDataTypeArray, setFourthDataTypeArray] = useState([]);


    const [dataLabels, setDataLabels] = useState([]);

   const [chosenDataType, setChosenDataType] = useState(0)


  useEffect(() => {
  functionOfStartDateOfChart(String(dataOfTime.startDateInfo));
  functionOfEndDateOfChart(String(dataOfTime.endDateInfo));
  }, [dataOfTime]);
  
  useEffect(() => {
    setLimit(100)
  }, [startDateOfChart, endDateOfChart]);
  
    useEffect(() => {
      async function run() {
  
        let path;
        if(startDateOfChart === "Current Date"){
          path= `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${url}/?sensor_unique_identifier=${dataOfDevice.deviceID}&limit=${limit}&offset=${offset}`
        }
        else{
          path = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${url}/?sensor_unique_identifier=${dataOfDevice.deviceID}&timestamp__range=${startDateOfChart},${endDateOfChart}&limit=${limit}&offset=${offset}`
        
        }
        const response = await axios.get(path);
        setMaxCount((response.data.count-1));
        const response2 = response.data.results;
        for (let i = 0; i < limit; i++) {
          if (typeof response2[i] == "undefined") {     
            break;
          }
  
              if(response2[i][dataTypes[0]] || response2[i][dataTypes[0]] === 0) holderFirstDataType.push(response2[i][dataTypes[0]]);
              if(response2[i][dataTypes[1]] || response2[i][dataTypes[1]] === 0) holderSecondDataType.push(response2[i][dataTypes[1]]);
              if(response2[i][dataTypes[2]] || response2[i][dataTypes[2]] === 0) holderThirdDataType.push(response2[i][dataTypes[2]]);
              if(response2[i][dataTypes[3]] || response2[i][dataTypes[3]] === 0) holderFourthDataType.push(response2[i][dataTypes[3]]);

              holderDataLabels.push(moment(response2[i].timestamp));
          


          if (i === limit - 1) {
            break;
          }
        }
      
  

  
        setFirstDataTypeArray(holderFirstDataType.reverse());
        setSecondDataTypeArray(holderSecondDataType.reverse());
        setThirdDataTypeArray(holderThirdDataType.reverse());
        setFourthDataTypeArray(holderFourthDataType.reverse());
        setDataLabels(holderDataLabels.reverse());
       
      }
      run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, startDateOfChart, endDateOfChart]);
  

    function functionOfSetLimit(valueOfLimit) { setLimit(valueOfLimit); }
    function functionOffset(valueOfOffset) { setOffset(valueOfOffset); }
    function functionOfStartDateOfChart(valueOfStartDate) { setStartDateOfChart(valueOfStartDate); }
    function functionOfEndDateOfChart(valueOfEndDate) {setEndDateOfChart(valueOfEndDate);}
  
  

  const styleOfSettingBlock = {
    position: "fixed",
    left: "17%", 
    top: "relative",
    zIndex: "3",
    border: "1px solid rgba(0, 0, 0, 0.04)",
    boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.3)",
    background: "rgba(255,255,255,0.85)",

  };

  const [openBox, setOpenBox] = useState(false);
  function setBoxOpen() {
    setOpenBox(old => !old);
  }

  const handleClickAway = () => {
    setOpenBox(false);
  };
  
  function ChangeMainDataType(value){
  setChosenDataType(value)
  }

  function chosenMainData(value){
    if(value === 0) return firstDataTypeArray
    if(value === 1) return secondDataTypeArray
    if(value === 2) return thirdDataTypeArray
    if(value === 3) return fourthDataTypeArray
  }
  
  return (
    <>
    <ClickAwayListener onClickAway={handleClickAway}>
    <div>
      <>
      <Tooltip title={"History"} placement="bottom">
<IconButton color="primary" onClick={() => setBoxOpen()} >
<HistoryIcon />
  </IconButton>
  </Tooltip>      
      </>
    <div>
    {openBox ? (
    <Box sx={styleOfSettingBlock}>
    <div>
    <div>
      <div className={"chart-wrapperPopUp"}>
        <ChartComponent
          fetchedDataValue={chosenMainData(chosenDataType)}
          fetchedDataLabel={dataLabels}
          title={titles[chosenDataType]}
          titles={titles}
          ChangeMainDataType={ChangeMainDataType}
        />
              <ChartHistorySlider
    maxCount={maxCount}
    functionOfSetLimit={functionOfSetLimit}
    functionOffset={functionOffset}
    startDateOfChart = {startDateOfChart}
    endDateOfChart = {endDateOfChart}
    specialUrlPath = {url}
    />
      </div>

    </div>
    </div>
  </Box>
  ) : null}
  </div>
  </div>
  </ClickAwayListener>
  </>
  );
}

