import React, { useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import {MaterialUISwitch} from './muiComponents';
import {ToastContainer } from 'react-toastify';
import { useDispatch } from "react-redux";
import { themeLightOrDarkDecider } from "../../actions/dataActions.js";
import logo from '../../logo-and-photo/venit.png';
import { useLocation } from 'react-router-dom';
import Environment from "./Environment";
import Device from "./Device"
import { useSelector } from "react-redux";
import {arrangeThePathNameToString} from './pageNamesDecider'
import Drawer from '../sidebar/Drawer'
import DeviceOrEnvSelection from './DeviceOrEnvSelection'
import { useHistory } from 'react-router-dom';

// 208 e sm:'none' eklendi
//   <Box sx={{ display: { xs: 'none',sm:'block', md: 'flex' } }}>   183   sm:'block' eklendi
export default function PrimarySearchAppBar({
  functionSetDarkTheme,
  functionSetLightThemeColor,
  darkMode,
  themeColor
}) {
  const dispatch = useDispatch();    
      const lightOrDarkDecider = () => {
        let deciderOfTheme = "light";

        if(darkMode === true){
          deciderOfTheme="dark";
        }
        dispatch(themeLightOrDarkDecider({
          themeDecider: deciderOfTheme
        }))
      }
    
      useEffect(() => {
        lightOrDarkDecider() 
      }, [darkMode]);

       const location = useLocation();
       const environment = useSelector (state => state.dataOfEnvironment);
   
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Account</MenuItem>
      <MaterialUISwitch sx={{ m: 1 }} checked={darkMode} onChange={() => functionSetDarkTheme()} />
      <input type="color"  onChange={(e) => functionSetLightThemeColor(e)} />
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const history = useHistory();
  const butonClick = () => {
    history.push('/');
  }
  

  return (
    <Box sx={{ flexGrow: 1 }}>
          <ToastContainer/>
      <AppBar position="fixed">
        <Toolbar
         sx={{
          display: "flex",
           justifyContent: "space-between",
          width:"97%",
          alignItems: 'center',
        }}
        >
          <div style={{
            display:"flex",
            justifyContent: "space-between",
            width:"50%"
          }}>
          <div
          style={{
            display:"flex",
            justifyContent: "space-between",

          
          }}>
        <div onClick={butonClick}>
        <img style={{maxHeight:'30px', maxWidth: '50px', paddingLeft:'1%', cursor: 'pointer'}} src={logo} alt="" className="logo"/> 
        </div>

   
          <Typography
            variant="h6"
            noWrap
            component="div"
            align= "center"
            sx={{ display: {  xs: 'none', sm: 'none' , md:'none' , lg:'block'}, 
            cursor: 'pointer', // Add this line
            }}
            onClick={butonClick}
          >
           &nbsp; &nbsp; VeNIT Lab&nbsp;
          </Typography>
          </div>

          <div>
          <DeviceOrEnvSelection/>
          </div>
          <Typography
            variant="h6"
            noWrap
            component="div"
            align= 'justify'
            sx={{ display: { xs: 'block', sm: 'block' , md:'block' } 
              
          }}
          >
              {arrangeThePathNameToString(location)}
          </Typography>
          </div>


           <div 
            style={{
              display:"flex",
             justifyContent:"start",
              
            }}>
           
         
          <Box sx={{ flexGrow: 1 ,  display: { xs: 'none', sm: 'none' , md:'none', lg: "flex" }}}>
          
          <Device/>
          </Box>

          <Box sx={{ display: { xs: 'none',sm:'none', md: 'flex' } }}>
          <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          {environment.isEnvironmentSelected && ( <Environment/>)}
        
            </IconButton>
           <Drawer/>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          



          <Box sx={{ display: { xs: 'flex',sm:'flex', md: 'none' } }}>          
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
