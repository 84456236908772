import * as React from 'react';
import {useState,  useEffect} from 'react';
import {render} from 'react-dom';
import axios from "axios";
import MapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from 'react-map-gl';
// bu mapgl nin içindeki değerleri değiştirdik
// sonrasında çalıştı.
import { useSelector } from "react-redux";
import Pins from './pins';
import CityInfo from './city-info';
import CITIES from './data/cities.json';
import Button from '@mui/material/Button';



const geolocateStyle = {
  top: 0,
  left: 0,
  padding: '10px'
};

const fullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: '10px'
};

const navStyle = {
  top: 72,
  left: 0,
  padding: '10px'
};

const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: '10px'
};


export default function App() {
  const [viewport, setViewport] = useState({
    width: "90vw",
    height: 'calc(100vh - 64px)',
    latitude: 40.906440,
    longitude: 29.155677,
    zoom: 14,
  });

  const dataOfServer = useSelector((dataOfServer => dataOfServer.dataOfServer));

  useEffect(() => {
    //Runs only on the first render
    async function run() {
      let pathOfEnvironment = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/watmon/environment/`
      
      const responseOfEnvironment = await axios.get(pathOfEnvironment);
     
      const environmentList = responseOfEnvironment.data.results;
      
      setEnvironments(environmentList)
      setDataLoaded(true)
    }
    run();
    
  }, []);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);
  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/satellite-v9")
  const dataOfTheme = useSelector (dataOfTheme  => dataOfTheme.setThemeDecider)
  const [environments, setEnvironments] =useState(CITIES)

  useEffect(() => {

if(dataOfTheme.themeDecider === "dark"){
  setMapStyle("mapbox://styles/mapbox/satellite-v9")
}
if(dataOfTheme.themeDecider === "light"){
  setMapStyle("mapbox://styles/mapbox/satellite-v9")
}

  }, [dataOfTheme])


  function setMapBoxStyleStreets(){
    setMapStyle("mapbox://styles/mapbox/streets-v11")
  }
  function setMapBoxStyleOutdoors(){
    setMapStyle("mapbox://styles/mapbox/outdoors-v11")
  }

  function setMapBoxStyleLight(){
    setMapStyle("mapbox://styles/mapbox/light-v10")
  }
  function setMapBoxStyleDark(){
    setMapStyle("mapbox://styles/mapbox/dark-v10")
  }
  function setMapBoxStyleSatellite(){
    setMapStyle("mapbox://styles/mapbox/satellite-v9")
  }
  function setMapBoxStyleSatelliteStreets(){
    setMapStyle("mapbox://styles/mapbox/satellite-streets-v11")
  }

  function setMapBoxStyleNavigationDay(){
    setMapStyle("mapbox://styles/mapbox/navigation-day-v1")
  }
  function setMapBoxStyleNavigationNight(){
    setMapStyle("mapbox://styles/mapbox/navigation-night-v1")
  }



  return (
    <>
    
       
      <MapGL
  mapboxApiAccessToken={"pk.eyJ1IjoibWFsaWt0dXJrIiwiYSI6ImNreDY0bWloNjA2ZDYydm8wZThuZTJyc3oifQ.DNtpqxguZ8lzGvCIIZOf5g"}
  {...viewport}  
  mapStyle={mapStyle}
  attributionControl={false} 
  onViewportChange={(nextViewport) => setViewport(nextViewport)}
      >
        
     {dataLoaded  &&  <Pins data={environments} onClick={setPopupInfo} /> }

{popupInfo && (
  <Popup
    tipSize={5}
    anchor="bottom"
    longitude={popupInfo.longitude}
    latitude={popupInfo.latitude}
    closeOnClick={false}
    onClose={setPopupInfo}
  >
    <CityInfo marketInfo={popupInfo} />
  </Popup>
)}


<Button  variant="contained" size="small"  onClick={setMapBoxStyleStreets}  sx={{ marginTop:"1rem", marginLeft:"3rem", float:"left"}} >ST</Button>
        <Button  variant="contained" size="small" onClick={setMapBoxStyleOutdoors} style={{ marginTop:"1rem",  marginLeft:"0.7rem", float:"left"}} >OUT</Button>
        <Button  variant="contained" size="small" onClick={setMapBoxStyleLight} style={{ marginTop:"1rem",  marginLeft:"0.7rem", float:"left"}} >LT</Button>
        <Button  variant="contained" size="small" onClick={setMapBoxStyleDark} style={{ marginTop:"1rem",  marginLeft:"0.7rem", float:"left"}} >DR</Button>
        <Button  variant="contained" size="small" onClick={setMapBoxStyleSatellite} style={{ marginTop:"1rem",  marginLeft:"0.7rem", float:"left"}} >ST</Button>
        <Button  variant="contained" size="small" onClick={setMapBoxStyleSatelliteStreets} style={{ marginTop:"1rem",  marginLeft:"0.7rem", float:"left"}} >SS</Button>
        <Button  variant="contained" size="small" onClick={setMapBoxStyleNavigationDay} style={{ marginTop:"1rem",  marginLeft:"0.7rem", float:"left"}} >DAY</Button>
        <Button  variant="contained" size="small" onClick={setMapBoxStyleNavigationNight} style={{ marginTop:"1rem",  marginLeft:"0.7rem", float:"left"}} >Night</Button>
        <GeolocateControl style={geolocateStyle} />
        <FullscreenControl style={fullscreenControlStyle} />
        <NavigationControl style={navStyle} />
        <ScaleControl style={scaleControlStyle} />
          
 
      </MapGL>
    
    </>
  );
}
//<AttributionControl compact={true} style={attributionStyle} />
export function renderToDom(container) {
  render(<App />, container);
}