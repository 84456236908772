import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch } from "react-redux";
import { getData } from "../actions/dataActions.js";
import Tooltip from '@mui/material/Tooltip';

function MarketInfo(props) {
  const { marketInfo } = props;
  const displayName = `Building: ${marketInfo.building}`;
  let deviceList = marketInfo.deviceList;
  const [counter, setCounter] = React.useState(600000);    // düşürme zamanı burada bunu 60000 den başlat bitmesin


  let deviceListOfFakeObject = [{}];
  for (let i = 0; i < deviceList.length; i++) {
    deviceListOfFakeObject[deviceList[i].name] = "dotYellowPulse";
  }
 

  const [deviceOnlineList, setDeviceOnlineList] = useState(
    deviceListOfFakeObject
  );

  useEffect(() => {
    for (let i = 0; i < deviceList.length; i++) {
      checkState(deviceList[i].name, deviceList[i].id, deviceList[i].ip);
    }

    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 3000);
    return () => clearInterval(timer);
  }, [counter]);

  const dispatch = useDispatch();
  const fetchData = (time) => {
    dispatch(
      getData({
        time: time,
        number: 15,
      })
    );
  };

  function checkState(deviceName, deviceID, deviceIP) {
    fetch(`http://127.0.0.1:3000/ping?host=${deviceIP}`)
      .then((response) => response.text())
      .then((response) => {
        return response;
      })
      .then((data) => {
        var array = JSON.parse(data);
        if (array.IsReachable === true) {
          let onlineDevice = [{}];
          onlineDevice[deviceName] = "dotGreen";
          setDeviceOnlineList((deviceOnlineList) => ({
            ...deviceOnlineList,
            ...onlineDevice,
          }));
        } else {
          let offlineDevice = [{}];
          offlineDevice[deviceName] = "dotRed";
          setDeviceOnlineList((deviceOnlineList) => ({
            ...deviceOnlineList,
            ...offlineDevice,
          }));
        }
      })
      .catch((err) => {
        console.log("ERR00:", err);
      });
  }

  function deviceInfoTip(deviceName, deviceId, deviceIp) {
    let deviceInfoText =
      `Device Name: ${deviceName} ` +
      `\nDeviceId: ${deviceId} ` +
      `\nDeviceIp: ${deviceIp}`;
    return deviceInfoText;
  }

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <h3>{displayName}</h3>

      <div>
        {deviceList.map((device) => (
          <Box
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <nav>
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      fetchData(device.id);
                    }}
                  >
                    <ListItemIcon>
                      <div
                        id={device.id}
                        className={deviceOnlineList[device.name]}
                      ></div>
                    </ListItemIcon>
                    <Tooltip
                      title={deviceInfoTip(device.name, device.id, device.ip)}
                      placement="bottom"
                    >
                      <ListItemText primary={device.name} />
                    </Tooltip>
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
          </Box>
        ))}
      </div>
    </Box>
  );
}
//  {test[0].deviceName.toString()}
export default React.memo(MarketInfo);

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_circles