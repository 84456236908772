import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { customAlphabet } from "nanoid";

const nanoid = customAlphabet("1234567890abcdef", 10);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhj3KygnpbMulDt7UJjSC7Kywwn2eNtvQ",
  authDomain: "monitoring-tool-dashboar-7c11e.firebaseapp.com",
  projectId: "monitoring-tool-dashboar-7c11e",
  storageBucket: "monitoring-tool-dashboar-7c11e.appspot.com",
  messagingSenderId: "1081858060934",
  appId: "1:1081858060934:web:947ac4655339992e2b5707"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);

/**
 * Upload an image to firebase storage
 * @param {File} file File object to be uploaded
 * @returns Path of the uploaded image
 */
const uploadImage = async (file) => {   // input elementinden aldığımı kullanacağım.
  return new Promise((resolve, reject) => {
    try {
      const storageRef = ref(storage, `diagrams/${nanoid()}`);

      uploadBytes(storageRef, file).then((snapshot) => {
        console.log("Uploaded a blob or file!", snapshot);
        resolve(snapshot.metadata.fullPath);
      });
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Retrieve an image from firebase storage
 * @param {string} imagePath Path of the image to be retrieved
 * @returns URL of the image
 * @example retrieveImage("diagrams/af675de")
 */
export const retrieveImage = async (imagePath) => {
  const storageRef = ref(storage, `${imagePath}`);
  const url = await getDownloadURL(storageRef);
  return url;
};

/**
 *
 * @param {String} environment Name of the new environment
 * @param {File} imageFile File object of the diagram
 * @returns Promise
 * @example createNewEnvironment("ExampleEnvironment", File)
 */
export const createNewEnvironment = (environmentName, imageFile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const imagePath = await uploadImage(imageFile);

      await setDoc(doc(db, "environments", environmentName), {
        name: environmentName,
        diagram: imagePath,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Return environment data by name, returns null if no such environment exists
 * @param {String} environment Name of the environment
 * @returns Promise
 * @example getEnvironment("ExampleEnvironment")
 */
export const getEnvironment = (environment) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "environments", environment);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let environmentData = docSnap.data();
        environmentData.diagram = await retrieveImage(environmentData.diagram);
        resolve(environmentData);
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Update an environment
 * @param {String} environmentName Name of the environment
 * @param {Object} data Data to be updated
 * @returns Promise
 * @example updateEnvironment("ExampleEnvironment", {name: "NewName", devices:{"device1": {"x": 20, "y": 30, height: 40, width: 50}}})
 */
export const updateEnvironment = (environmentName, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "environments", environmentName);

      await setDoc(docRef, data, { merge: true });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Delete an environment
 * @param {String} environmentName Name of the environment
 * @returns Promise
 * @example deleteEnvironment("ExampleEnvironment")
 */
export const deleteEnvironment = (environmentName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "environments", environmentName);

      await deleteDoc(docRef);

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
