import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import ChartComponent from "./ChartComponent";
import PropTypes from 'prop-types'

export default function LineChartTraceRoute({
  YaxisLabel = "Delay Results(ms)",
  specialUrlPath = "watmon/trace_results",
  primaryValue = "delay_results",
  urlExtension = "&hop_number=1",
}) {




  const dataOfTime = useSelector((dataOfTime) => dataOfTime.dataOfTime);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [startDateOfChart, setStartDateOfChart] = useState("Current Date");
  const [endDateOfChart, setEndDateOfChart] = useState(" ");


 const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer); 
 const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);
  const labelsYaxisArray = [];
  const mainValueArray = [];
  const meanValueArray = [];
  const [mainValues, setMainValues] = useState([]);
  const [labelYaxisValues, setLabelYaxisValues] = useState([]);
  const [meanValues, setMeanValues] = useState([]);

  const [chartMinData, setChartMinData] = useState(0.0);
  const [chartMaxData, setChartMaxData] = useState(0.0);
  const [chartStandardDeviation, setChartStandardDeviation] = useState(0.0);

  useEffect(() => {
    async function run() {
      let path;
      if (startDateOfChart === "Current Date") {
        path = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${specialUrlPath}/?sensor_unique_identifier=${dataOfDevice.deviceID}&limit=${limit}&offset=${offset}${urlExtension}`;
      } else {
        path = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${specialUrlPath}/?sensor_unique_identifier=${dataOfDevice.deviceID}&timestamp__range=${startDateOfChart},${endDateOfChart}&limit=${limit}&offset=${offset}${urlExtension}`;
      }
      const response = await axios.get(path);
      setMaxCount(response.data.count - 1);
      const response2 = response.data.results;
      let totalMeanValue = 0;
      let counterOfMeanValue = 1.0;
      let holderOfSingleAverageValue = 0;
      let minValue = 1;
      let maxValue = 0;
      for (let i = 0; i < limit; i++) {
        if (typeof response2[i] == "undefined") {
          // gidebildiği yere kadar gidiyor, sonrasında daha fazla data yoksa undefined geliyor ve foru breakliyoruz.
          break;
        }

        if (response2[i][primaryValue] == null) {
          //           networkHttpRtt.unshift(0.0);
        } else {
          let valueOfData;
          if (typeof response2[i][primaryValue] == "object") {
            let j;
            let totalOfArrayValue = 0;
            for (j = 0; j < response2[i][primaryValue].length; j++) {
              totalOfArrayValue =
                totalOfArrayValue + parseFloat(response2[i][primaryValue][j]);
            }
            valueOfData =
              Math.round(
                (totalOfArrayValue * 100.0) / response2[i][primaryValue].length
              ) / 100;
          
            valueOfData = valueOfData + "";
         
          } else {
            valueOfData = response2[i][primaryValue];
          }

          if (parseFloat(valueOfData) > maxValue) {
            maxValue = valueOfData;
          }

          if (parseFloat(valueOfData) <= minValue) {
            minValue = valueOfData;
          }

          mainValueArray.push(valueOfData);
          labelsYaxisArray.push(
            moment(response2[i].timestamp).format("DD/MM HH:mm")
          );
          setChartMinData(minValue);
          setChartMaxData(maxValue);
        }
        if (i === limit - 1) {
          break;
        }
      }
   

      for (let i = mainValueArray.length - 1; -1 < i; i--) {
        totalMeanValue = totalMeanValue + parseFloat(mainValueArray[i]);
        holderOfSingleAverageValue = parseFloat(
          totalMeanValue / counterOfMeanValue
        );
        meanValueArray.push(holderOfSingleAverageValue);
        counterOfMeanValue++;
      }
      let dataCount = response.data.count - 1;
      let tenPercentageOfMaxCount = Math.floor(
        parseFloat(parseFloat(dataCount) / 10)
      );
      let valuesOfMaxCountEachTenPercent = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      for (let i = 0; i < 11; i++) {
        valuesOfMaxCountEachTenPercent[i] =
          dataCount - tenPercentageOfMaxCount * i;
      }

      setMainValues(mainValueArray.reverse());
      setLabelYaxisValues(labelsYaxisArray.reverse());
      setMeanValues(meanValueArray);
    }
    run();
  }, [limit, startDateOfChart, endDateOfChart]);

  useEffect(() => {
    function calculationOfStandardDeviation() {
      // burada eskiyi alıyo olabilir, o zaman şey yapıcaz useeffectte chartDataHttpRtt değişince yapıcaz.
      let totalAmount = 0.0;
      let rttMean = parseFloat(meanValues);
      for (let i = 0; i < mainValues.length; i++) {
        totalAmount =
          totalAmount +
          Math.pow(parseFloat(parseFloat(mainValues[i]) - rttMean), 2);
      }
      totalAmount = totalAmount / parseFloat(mainValues.length - 1);
      totalAmount = Math.pow(parseFloat(totalAmount), 0.5);
      setChartStandardDeviation(totalAmount);
    }
    calculationOfStandardDeviation();
  }, [meanValues]);

  return (
    <div>
      <div className={"chart-wrapperPopUp"}>
        <div
          style={{ float: "right", maxHeight: "0.3rem", marginTop: "-0.6rem" }}
        ></div>
        <ChartComponent
          chartMaxData={chartMaxData}
          chartMinData={chartMinData}
          chartDataHttpRttMean={meanValues}
          chartDataHttpRtt={mainValues}
          chartNetworkHttpLabel={labelYaxisValues}
          chartStandardDeviation={chartStandardDeviation}
          YaxisLabel={YaxisLabel}
        />
      </div>
    </div>
  );
}

