import { combineReducers } from "redux";
import {dataOfDevice, dataOfTime, setTimeLimitOfListeningDevice, setThemeDecider, dataOfServer,  dataOfEnvironment, anomalies,  dataOfAnomaly } from "./dataReducer";
// dataOfSample is just sample of data, we used this sample data for drawing first chart. After this, we just using some of test environment
const rootReducer = combineReducers({
  dataOfDevice : dataOfDevice,
  dataOfTime : dataOfTime,
  setTimeLimitOfListeningDevice : setTimeLimitOfListeningDevice,
  setThemeDecider : setThemeDecider,
  dataOfServer : dataOfServer,
  anomalies: anomalies,
  dataOfEnvironment : dataOfEnvironment,
  dataOfAnomaly : dataOfAnomaly
})

export default rootReducer;