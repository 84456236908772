import React from 'react'
import './circleLoop.css'

export default function CircleLoop() {


    return (
        <div>
            <div className="CircleLayout">
            <section>
                <div className="box">
                    <div className="circle"></div>
                    <div className="circle"></div>


                    <div class="dot" style= {{  '--i': 1}}  ><b>V</b></div>
                    <div class="dot" style= {{  '--i': 2}}  ><b>e</b></div>
                    <div class="dot" style= {{  '--i': 3}}  ><b>n</b></div>
                    <div class="dot" style= {{ '--i': 4 }}  ><b>i</b></div>
                    <div class="dot" style= {{  '--i': 5}}  ><b>t</b></div>

                    <div class="dot" style= {{  '--i': 6}}  ><b></b></div>
                    <div class="dot" style= {{  '--i': 7}}  ><b>L</b></div>
                    <div class="dot" style= {{  '--i': 8}}  ><b>a</b></div>
                    <div class="dot" style= {{ '--i': 9}}   ><b>b</b></div>
                    <div class="dot" style= {{  '--i': 10}} ><b></b></div>
                    <div class="dot" style= {{  '--i': 11}}  ><b></b></div>

                </div>
            </section>

            </div>

        </div>
    )
}
