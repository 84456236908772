

export function arrangeThePathNameToString(location){

    let headerPageName = location.pathname.toString().replace('/', '');
    if(headerPageName === "")
      headerPageName = "Overview"
    else if(headerPageName === "traceroute") 
      headerPageName = "TraceRoute"
      else if(headerPageName === "hardware") 
      headerPageName = "Hardware Resource Overview"
      else if(headerPageName === "device-selection-map-on-image") 
      headerPageName = "Environment Overview"
      else if(headerPageName === "environment-hardware") 
      headerPageName = "Environment Hardware Overview"
      else if(headerPageName === "protocol") 
      headerPageName = "Protocol Density"
      else if(headerPageName === "emulator") 
      headerPageName = "Emulator"
      else if(headerPageName === "link-info") 
      headerPageName = "Wireless Link"
      else if(headerPageName === "no-connection-duration") 
      headerPageName = "Connection Duration Info"
      else if(headerPageName === "connection-states") 
      headerPageName = "Connection States"
      else if(headerPageName === "mqtt-traceroute") 
      headerPageName = "Real Time TraceRoute"
      else if(headerPageName === "environment-link-info") 
      headerPageName = "Environment Wireless Link"
      else if(headerPageName === "environment-emulator") 
      headerPageName = "Environment Emulator"
      else if(headerPageName === "device-environment-info") 
      headerPageName = "Device and Environment Settings"
      else if(headerPageName === "anomaly") 
      headerPageName = "Anomaly Info"
      else if(headerPageName === "anomaly") 
      headerPageName = "Anomaly Info"
      else if(headerPageName === "server-settings") 
      headerPageName = "Server & Connection Settings"
      else if(headerPageName === "about-us") 
      headerPageName = "About Us"
      



      
      
      
    else{
      headerPageName = headerPageName+"";
    }
 return headerPageName
}



