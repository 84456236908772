import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import "./sidebar.css"
import { LineStyle, Timeline, MyLocation} from "@mui/icons-material"
import { Link } from "react-router-dom";
import { useSelector} from "react-redux";
import React from "react";

import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import List from '@mui/material/List';

export default function PrimarySearchAppBar() {
  
  const dataOfDevice = useSelector((dataOfDevice => dataOfDevice.dataOfDevice));
  const dataOfEnvironment = useSelector((dataOfEnvironment => dataOfEnvironment.dataOfEnvironment));

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };



    const CustomContainer= styled(Container)(({ theme }) => ({
          height: "100vh",
          position: "sticky",
          overflow: "auto",
          top:0,
            paddingTop:theme.spacing(10),
            backgroundColor: theme.palette.primary.main
      }));

    return(
        
        <CustomContainer>
             <div className="sidebarMenu">
                 <h3 className="sidebarTitle">Environment</h3>
                  <ul className="sidebarList">
                  <Link to="/" className="link">
                    <li className="sidebarListItem">
                     <LineStyle className="sidebarIcon"/>
                    Device Selection
                    </li>
                    </Link>

{dataOfEnvironment.isEnvironmentSelected && (
  <>
                    <Link to="/device-selection-map-on-image" className="link">
                    <li className="sidebarListItem">
                     <LineStyle className="sidebarIcon"/>
                    Schema Selection
                    </li>
                    </Link>


          { dataOfDevice.loading &&
          <>
                    <Link to="/traceroute" className="link">
                    <li className="sidebarListItem">
                     <LineStyle className="sidebarIcon"/>
                    TraceRoute
                    </li>
                    </Link>

</>
                    
}




                    </>



                    
)}




                  </ul>
             </div>
             {dataOfDevice.loading &&       // değişecek burası
             <div className="sidebarMenu">
                 <h3 className="sidebarTitle">Real Time Data</h3>
                  <ul className="sidebarList">



                    <Link to="/link-info" className="link">
                    <li className="sidebarListItem">
                     <Timeline className="sidebarIcon"/>
                     Wireless Link
                    </li>
                    </Link>


                    <Link to="/emulator" className="link">
                    <li className="sidebarListItem">
                     <Timeline className="sidebarIcon"/>
                     Http Application Delay
                    </li>
                    </Link>


                    <Link to="/hardware" className="link">
                    <li className="sidebarListItem">
                     <Timeline className="sidebarIcon"/>
                     Hardware
                    </li>
                    </Link>




                   
                    <li className="sidebarListItem" onClick={handleClick}>
                     <Timeline className="sidebarIcon"/>
                     
                     <ListItemText primary="Network" />
     
        {open ? <ExpandLess /> : <ExpandMore />}
        </li>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

        <Link to="/protocol" className="link">
                    <li className="sidebarListItem">
                     <Timeline className="sidebarIcon"/>
                     Protocol
                    </li>
                    </Link>


                    <Link to="/connection-states" className="link">
                    <li className="sidebarListItem">
                     <Timeline className="sidebarIcon"/>
                     Connection States
                    </li>
                    </Link>

        </List>
      </Collapse>


                  </ul>
             </div>

                  }               
   
             <div className="sidebarMenu">
                 <h3 className="sidebarTitle">Settings and Help-Center</h3>
                  <ul className="sidebarList">
                  
                  
                  <Link to="/device-environment-info" className="link">
                    <li className="sidebarListItem">
                     <MyLocation className="sidebarIcon"/>
                     Env. and Device Settings
                    </li>
                    </Link>

                    <Link to="/anomaly" className="link">
                    <li className="sidebarListItem">
                     <MyLocation className="sidebarIcon"/>
                     Anomaly
                    </li>
                    </Link>

                    <Link to="/server-settings" className="link">
                    <li className="sidebarListItem">
                     <MyLocation className="sidebarIcon"/>
                     Server Settings
                    </li>
                    </Link>



                    <Link to="/about-us" className="link">
                    <li className="sidebarListItem">
                     <MyLocation className="sidebarIcon"/>
                     About Us
                    </li>
                    </Link>



                  </ul>
             </div>
         
        </CustomContainer>
      
    )

}