import './App.css';
import './pages/history-pages/oneChart.css'
import Grid from '@mui/material/Grid';
import { useState } from "react";
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import React from 'react';
import TopBar from "./components/topbar/TopBar";
import XSidebar from './components/sidebar/SideBar';
import { BrowserRouter as Router, Switch, Route, HashRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import TrafficTcp from './pages/update-pages/traffic-tcp-page/TrafficTcp.js'; mqtt var bunda çalıştırma import ettiğinde bile mqtt bağlantısını açıyor
// çok garip bir durum, sadece import etmen yetiyor, bir yere koyma, yine hata atıyor bağlantı hatası bundan dolayı çıkarttık
import DeviceInfo from './pages/homepage/deviceInfo/DeviceInfo';
import CircleLoop from './components/circleLoop/CircleLoop.js'
import AboutUs from './pages/homepage/about-us/AboutUs.js'
import DateDeciderPage from './pages/settings/DateDeciderPage';
import DeviceSelectionMap from './map/AppMap'
import ServerSetting from './pages/homepage/serverSetting/ServerSetting'
import TraceRoot from './pages/homepage/traceRoot/TraceRoot'
import MqttTraceRoute from './pages/mqtt-pages/traceroot/mqttTraceroot'
import Anomaly from './pages/homepage/anomaly/Anomaly'
import {ImageMap} from './map/ImageMap/ImageMap'
import RadarChart from './components/chart/Radar/MainOneChartOnlyHistory'
import MainChart from './components/chart/MainChart';
import LineBar from './components/chart/LineBar/MainLineBar'
import BarLine from './components/chart/BarLine/MainChart'
import Bar from './components/chart/Bar/MainChart'
import MultiCihazChart from './components/environmentChart/MultiCihazChart';
import DeviceAndEnvironmentInfo from './pages/homepage/setting/DeviceAndEnv'
import Messages from './pages/homepage/Messages.jsx'
import ChartBackGround from './components/chart/ChartBackGround';
import AddingEnvironment from './pages/environment/AddingEnvironment';
import Tradeview from './pages/homepage/denemeSayfalari/Tradeview';
import TraceRoute from './pages/homepage/traceRoot/TraceRoute';
toast.configure()

function App() {

const [darkMode, setDarkMode] = useState(false);
const [themeColor, setThemeColor] = useState("#1487B0")

const darkTheme = createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: "rgb(40,40,40,0.6)",
      secondary: yellow[500],
      white: "#fafafa",
      icon: yellow[500],
    },
  },
});

const lightTheme = createTheme({
  palette: {
    primary: {
      main: themeColor,
      secondary: yellow[50],
      white: "#fafafa",
      icon: yellow[500],
    },
  },
});

function functionSetDarkTheme(){
  setDarkMode(!darkMode)
}

function functionSetLightThemeColor(e){
  setThemeColor(e.target.value)
}


  return (
    <HashRouter >
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      
      <Paper style={{ height:"100%"}}>
      <div className="wholeWebSiteBackGroundColor">
    <TopBar functionSetDarkTheme={functionSetDarkTheme} functionSetLightThemeColor={functionSetLightThemeColor}  darkMode={darkMode} themeColor={themeColor}></TopBar>
    <Grid container>
<Grid item  xs={4} sm={3} md={3} lg={2} xl={1.7}>
<XSidebar/>
</Grid>
<Grid item  xs={8} sm={9} md={9} lg={10} xl={10.3}>
  <div 
  style={{
    display: "flex",
    marginTop:"64px",
    justifyContent: "center",
    alignItems: "center"
  }} 
  >
<Switch>

<Route exact path="/">
<DeviceSelectionMap/>
</Route>

<Route path="/device-selection-map-on-image">
<ImageMap/>
</Route>



<Route path="/adding-environment">
<AddingEnvironment/>
</Route>

<Route path="/chartbackground">
<ChartBackGround/>
</Route>


<Route path="/device-selection-map">
<DeviceSelectionMap/>
</Route>

<Route path="/device-info">
<DeviceInfo/>
</Route>


<Route path="/device-environment-info">
<DeviceAndEnvironmentInfo/>
</Route>

<Route path="/messages">
  <Messages/>
</Route>



<Route path="/hardware" key="hardware">
<MainChart
  titles = {["Free Disk Space (%)", "CPU Load (%)", "Available Memory (%)"]}
  url = "watmon/hardware"
  dataTypes = {[
    "free_disk_percentage",
    "cpu_load",
    "available_mem_percent",
  ]}
/>
</Route>

<Route path="/link-info" key="link-info">
<LineBar
  titles = {["DBM", "Link Level"]}
  url = "watmon/link_info"
  dataTypes = {[
    "dbm_level",
    "link_level",
    
  ]}
  barTypes = {["discarded_packets", "Discarded Packets"]}
/>
</Route>

<Route path="/emulator" key="emulator">
<MainChart
  titles = {["Http Application Delay"]}
  url = "watmon/emulator_events"
  dataTypes = {[
    "elapsed_time",
  ]}
/>
</Route>

<Route path="/no-connection-duration" key="no-connection-duration">
<Bar
  titles = {["Connection States"]}
  url = "watmon/no_connection_duration"
  dataTypes = {[
    "duration",
  ]}
  timeLabel="time_start"
/>
</Route>

<Route path="/protocol" key="protocol">
<BarLine/>
</Route>

<Route path="/environment-hardware" key="environment-hardware">
<MultiCihazChart
  titles = {["Free Disk Space (%)", "CPU Load (%)", "Available Memory (%)"]}
  url = "watmon/hardware"
  dataTypes = {[
    "free_disk_percentage",
    "cpu_load",
    "available_mem_percent",
  ]}

/>
</Route>


<Route path="/environment-emulator" key="environment-emulator">
<MultiCihazChart
  titles = {["Http Application Delay"]}
  url = "watmon/emulator_events"
  dataTypes = {[
    "elapsed_time",
  ]}

/>
</Route>


<Route path="/environment-link-info" key="environment-link-info">
<MultiCihazChart
  titles = {["DBM", "Link Level"]}
  url = "watmon/link_info"
  dataTypes = {[
    "dbm_level",
    "link_level",
    
  ]}

/>
</Route>





<Route path="/connection-states">
<RadarChart/>
</Route>

<Route path="/traceroute">
<TraceRoute/>
</Route>


<Route path="/about-us">
<AboutUs/>
</Route>

<Route path="/date-decider-page">
<DateDeciderPage/>
</Route>

<Route path="/circle-loop">
<CircleLoop/>
</Route>


<Route path="/mqtt-traceroute">
<MqttTraceRoute/>
</Route>


<Route path="/server-settings">
<ServerSetting/> 
</Route>




<Route path="/anomaly">
<Anomaly/> 
</Route>



</Switch>


</div>
</Grid>

    </Grid>
    </div>
    </Paper>
    </ThemeProvider>
    </HashRouter>
   
  );
}
export default App;