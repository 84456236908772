import React from 'react'
import Slider from '@mui/material/Slider';
import {marksOfChartLineBorder, getStyleOfSettingBlock} from './ChartComponentOptions'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';


function ChartSettingSliders({
    borderWidthValueMean,
    borderWidthValue,
    chartPointRadiusValue,
    chartPointRadiusValueMean,
    borderLineShow,
    backGroundShow,
    changeChartRangeLineDrawingBorder,
    changeChartRangeLineDrawingBorderMean,
    changeChartRangePointRadius,
    changeChartRangePointRadiusMean,
    disableChartLine,
    fillChartBackGround,
}) {


    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };
    
    const handleClickAway = () => {
      setOpen(false);
    };
  
  return (
    <>
<ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{position:'absolute'}}>
<div style={{}}>
      <IconButton color="info" size="small"  onClick={handleClick} >
        <SettingsIcon color="info"/>
        </IconButton>
        </div>
        {open ? (
          <Box sx={getStyleOfSettingBlock}>
            <div>
                 <h4 style={{textAlign:'center'}}>Settings</h4>
              <label>Main Border Width Range</label>
              <Slider
  value={borderWidthValue} 
  onChange={changeChartRangeLineDrawingBorder}
  step={0.3}
  marks = { marksOfChartLineBorder()}
  min={0.1}
  max={5}
  valueLabelDisplay="auto"
  size="small"
/>
              <label>Mean Border Width Range</label>
              <Slider
  value={borderWidthValueMean} 
  onChange={changeChartRangeLineDrawingBorderMean}
  step={0.3}
  marks = { marksOfChartLineBorder()}
  min={0.1}
  max={5}
  valueLabelDisplay="auto"
  color="error"
  size="small"
/>
              <label>Point Radius Range</label>
              <Slider
                min={0.1}
                max={5}
                step={0.2}
                value={chartPointRadiusValue}
                valueLabelDisplay="auto"
                marks = { marksOfChartLineBorder() }
                onChange={changeChartRangePointRadius}
                size="small"
           
                />
              <label>Mean Point Radius Range</label>
              <Slider
                min={0.1}
                max={5}
                step={0.2}
                value={chartPointRadiusValueMean}
                marks = { marksOfChartLineBorder() }
                valueLabelDisplay="auto"
                onChange={changeChartRangePointRadiusMean}
                color="error"
                size="small"
                />

<input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {borderLineShow}
                onClick={(e) => disableChartLine(e)}
              ></input>
                    <label style={{width: "auto", marginRight:"110px"}} >Show ChartLine</label>
            
              <input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {backGroundShow}
                onClick={(e) => fillChartBackGround(e)}
              ></input>
                <label>Fill ChartBackGround</label>
            </div>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
    </>
  )
}

export default ChartSettingSliders