import React from 'react'
import {Doughnut } from "react-chartjs-2";
import Tooltip from '@mui/material/Tooltip';
function GaugeChart({redPercentage=80 , topic="", tooltip="tooltip"}) {

//const [redPercentage, setRedPercentage] = useState(80)
// const[lineColor, setLineColor] = useState("rgb(0,255,0)");
function randomFill(){
    let redColor = Math.round((255/100)*redPercentage);
    let greenColor = Math.round((255/100)*(100-redPercentage));
    let color = `rgb( ${redColor}, ${greenColor} ,0)`;
    return color;
}

    const optionsPie = {
        animation: true,
        rotation: 270, // start angle in degrees
        circumference: 180, // sweep angle in degrees,
        aspectRatio:2,
        cutout:"60%",
        plugins:{
          legend:{
            display:false,
          },
          tooltip: {
            enabled: false
          },
          scale: {
            ticks: {
                display: false
            }
        },

        },

        tooltips: {
          enabled: false
     }


      };

      

  return (
<>
<Tooltip title={tooltip} placement="bottom" arrow>
        <div style={{
            display:"inline-flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width:"50px",
            marginLeft:"0.3rem"
        }}>                               
<Doughnut style={{
       position:" relative",
      top: 3
}}
          data={{
            labels: [`Full ${redPercentage}% ${topic}`, `Avaliable ${(100 - redPercentage)}%`],
            datasets: [{
              label:'Last Time Protocol Density',
              data:[ (100 - redPercentage), redPercentage ],
              backgroundColor: [
                'rgba(0, 255, 0, 0.3)',
                'rgba(255, 0, 0, 0.3)',
               
              ],
              borderColor: [
                'rgba(0, 255, 0, 1)',
                'rgba(255, 0, 0, 1)',
        
              ],
              borderWidth: 1,
        //      showLine: false, // disable for a single dataset
            }         
          ] 



           }}  options={optionsPie}
        />


        <div style={{
            borderLeft:`4rem solid ${randomFill()}`,
            height: "1rem",
            position: "absolute",
            bottom: 0,
            transform: `rotate(${90 - (redPercentage * 1.8) }deg)`,
            transformOrigin:"bottom center",
            clipPath: "polygon(50% 0, 50% 0, 52% 100%, 48% 100%)",
    }}>
        </div>
        <p 
    style={{
      color:`${randomFill()}`,
      textAlign:"center",
      position:"absolute",
      bottom:0,
      fontSize: "0.8rem",
      marginBottom:"-1rem",
      fontWeight: "bold"
    }}>{topic}</p>
        </div>
        </Tooltip>
        </>
  )
}

export default GaugeChart