import React, { useState} from "react";
import { Line, Chart, Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import options , {marksOfChartLineBorder, getStyleOfSettingBlock} from "./ChartOptions"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Slider from '@mui/material/Slider';

Chart.register(annotationPlugin);

const ChartComponent = ({
  dataValues,
  dataLabels,
  titles,
  index
}) => {

  const [borderWidthValue, setBorderWidthValue] = useState(2.5);
  const [chartPointRadiusValue, setChartPointRadiusValue] = useState(0.5);
  const [borderLineShow, setBorderLineShow] = useState(true);
  const [backGroundShow, setBackGroundShow] = useState(false);



  function changeChartRangeLineDrawingBorder(e) {
    setBorderWidthValue(e.target.value);
  }


  function changeChartRangePointRadius(e) {
    setChartPointRadiusValue(e.target.value);
  }


  function disableChartLine(e) {
    setBorderLineShow(e.target.checked);
  }

  function fillChartBackGround(e) {
    setBackGroundShow(e.target.checked);
  }


  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  
  const handleClickAway = () => {
    setOpen(false);
  };

  function decideLineColor(index){
  if(index === 0) return "11, 50, 255"
  if(index === 1) return "230, 216, 0"
  if(index === 2) return "155, 25, 245"
  if(index === 3) return "0, 191, 160"
  }
  function decideHeighOfThePage(length){
  if(length === 1) return "calc(90vh - 128px)"
  if(length === 2) return "calc(55vh - 128px)"
  if(length === 3) return "calc(40vh - 128px)"
  if(length === 4) return "calc(33vh - 128px)"
  }

  return (
    <div 
    style={{
      height: `${decideHeighOfThePage(titles.length)}`,
      width: "80vw",
      alignItems: "center",
      alignContent: "center",
      alignSelf: "center",
    }}    
    >
     
     <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{position:'absolute'}}>
<div style={{}}>
      <IconButton color="info" size="small"  onClick={handleClick} >
        <SettingsIcon color="info"/>
        </IconButton>
        </div>
        {open ? (
          <Box sx={getStyleOfSettingBlock}>
            <div>
                 <h4 style={{textAlign:'center'}}>Settings</h4>

       

              <label>Main Border Width Range</label>
              <Slider
  value={borderWidthValue} 
  onChange={changeChartRangeLineDrawingBorder}
  step={0.3}
  marks = { marksOfChartLineBorder()}
  min={0.1}
  max={5}
  valueLabelDisplay="auto"
  size="small"
/>

              <label>Point Radius Range</label>
              <Slider
                min={0.1}
                max={5}
                step={0.2}
                value={chartPointRadiusValue}
                valueLabelDisplay="auto"
                marks = { marksOfChartLineBorder() }
                onChange={changeChartRangePointRadius}
                size="small"
           
                />


<input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {borderLineShow}
                onClick={(e) => disableChartLine(e)}
              ></input>
                    <label style={{width: "auto", marginRight:"110px"}} >Show ChartLine</label>
            
              <input style={{width: "auto", marginRight:"20px"}}
                type="checkbox"
                checked = {backGroundShow}
                onClick={(e) => fillChartBackGround(e)}
              ></input>
                <label>Fill ChartBackGround</label>
            </div>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>

        <Bar
        style={{
          height:  `${decideHeighOfThePage(titles.length)}`,
          width: "80vw",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}         // chartSize      "chart-lineOneChart"  "chart-line-mqqt-link"           
          data={{
            labels: dataLabels,
            datasets: [
              {
                label: titles[index],
                data: dataValues,
                borderColor: `rgba(${decideLineColor(index)})`,
                showLine: borderLineShow,
                pointRadius: chartPointRadiusValue,
                backgroundColor: `rgba(${decideLineColor(index)},1)`,
                hoverRadius: 3,
                hoverBorderWidth: 3,
                fill: backGroundShow,
              },
            ],
          }}
          options={options(borderWidthValue, 
            dataValues.reduce((a, b) => Math.max(a, b), -Infinity), 
            dataValues.reduce((a, b) => Math.min(a, b), Infinity),
            dataValues.reduce( ( p, c ) => p + c, 0 ) / dataValues.length, 
            titles[index])}
        />
      </div>

  );
};
export default ChartComponent;
