import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import WarningBox from "../../mqtt/MqttWarningBox.js";
import moment from "moment";
import WarningLight from "../../mqtt/WarningLight.jsx";
let mqttCli = require("mqtt");

let client;
function AppMqtt({
  url = "watmon/protocol_density",
  dataType = "type",
  MainHistory,
}) {
  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
  const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);

  const [labels, setLabels] = useState([]);
  const [wholeDataSet, setWholeDataSet] = useState();

  const color = [
    "rgb(160, 195, 210)",
    "rgb(232, 223, 202)",
    "rgb(234, 199, 199)",
    "rgb(176, 139, 187)",
    "rgb(134, 200, 188)",
    "rgb(255, 212, 178)",
    "rgb(255, 209, 232)",
    "rgb(223, 211, 195)",
    "rgb(232, 243, 214)",
    "rgb(101, 100, 124)",
    "rgb(183, 196, 207)",
    "rgb(150, 126, 118)",
  ];
  function prepareDatasets(titles, twoDimensionalArray, lineData) {
    const datasetsTwo = titles.map((title, index) => ({
      label: title,
      data: twoDimensionalArray[index],
      backgroundColor: color[index],
      yAxisID: "y",
      order: 2,
    }));
    const dataSetsThree = {
      type: "line",
      label: "Total Retransmissions",
      data: lineData,
      borderColor: "rgb(233, 119, 119)",
      backgroundColor: "rgb(233, 119, 119)",
      yAxisID: "y1",
      order: 1,
      showLine: true,
      borderWidth: 10 * 1.0 / lineData.length  
    };
    const wholeDataSets = datasetsTwo.concat(dataSetsThree);
    return wholeDataSets;
  }

  const optionsMqtt = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };

  useEffect(() => {
    function startMqttSubscribe() {
      const types = [];
      const dataTwo = [];
      const labels = [];
      const lineData = [];
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
      
      if (client.connected === false) {
        
      }
      client.subscribe(`${url}/${dataOfDevice.deviceID}/`, () => {
        //When a message arrives, print it to the console
        client.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
          console.log("dataAll:", dataAll)
          for (let i = 0; i < dataAll.type.length; i++) {
            if (!types.includes(dataAll.type[i])) {
              types.push(dataAll.type[i]);
              let newArray = [];
              for (let k = 0; k < labels.length; k++) {
                newArray.push(0);
              }
              newArray.push(dataAll.density[i]);

              dataTwo.push(newArray);
            } else {
              dataTwo[types.indexOf(dataAll.type[i])].push(
                dataAll.density[i]
              );
            }
          }

          labels.push(moment(dataAll.end_datep).format("LT"));
          lineData.push(dataAll.total_retransmissions);

          for (let j = 0; j < dataTwo.length; j++) {
            if (dataTwo[j].length < labels.length) dataTwo[j].push(0);
          }
          
          updateUstates(labels, types, dataTwo, lineData);
        });
      });

      return client;
    }
    startMqttSubscribe();
  }, []);

  function updateUstates(labels, types, dataTwo, lineData) {
    const holderLabel = [...labels];
    const holderType = [...types];
    const holderDataTwo = [...dataTwo];

    setLabels(holderLabel);
    setWholeDataSet(prepareDatasets(holderType, holderDataTwo, lineData));
    
  }

  useEffect(() => {
    return () => {
      
      client.end();
    };
  }, []);

  const options = {
    animation: false,
    plugins: {
      title: {
        display: true,
        text: "PROTOCOL DENSITY",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        display: true,
        position: "left",
        ticks: {
          color: "rgba(0, 0, 0, 1)",
        },
        grid: {
          drawBorder: true,
          drawTicks: true,
          color: "rgba(0, 0, 0, 0.2)",
        },
        title: {
          display: true,
          text: "PROTOCOL DENSITY",
          font: {
            size: 17,
          },
        },
      },

      y1: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Total Retransmissions",
          font: {
            size: 15,
          },
        },
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className={"chart-wrapperOneBigChart"}>
          <WarningLight url={url} />
          <div
            className="topbarIconContainer"
            style={{
              float: "left",
              marginTop: "-0.1rem",
              marginBottom: "-2.2rem",
            }}
          >
           {MainHistory}
          </div>
          <Bar
            style={{
              height: `75vh`,
              width: "75vw",
              alignItems: "center",
              alignContent: "center",
              alignSelf: "center",
            }}
            options={options}
            data={{
              labels,
              datasets: wholeDataSet,
            }}
          />
        </div>
      </div>
    </>
  );
}
export default AppMqtt;
