import React, { useState,useEffect, useRef } from "react";
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";


export default function ChartHistorySlider({
    maxCount,
    functionOfSetLimit,
    functionOffset,
    startDateOfChart,
    endDateOfChart,
    specialUrlPath,
}) {

  const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer); 
  const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);
  const [sliderMarksValue, setSliderMarksValue] = useState([1,2,3,4,5,6,7,8,9,10,11]);
  const [sliderMarksLabel, setSliderMarksLabel] = useState(["1","2","3","4","5","6","7","8","9","10","11"]);


  const initialRenderMin = useRef(true);
  const initialRenderMax = useRef(true);
  const limitOfFetchingMaxDataAmountRed = 15000;
  const limitOfFetchingWarningDataAmountYellow = 10000;


  const [colorOfFetchingButton, setColorOfFetchingButton] = useState("info");
  const [toolTipText, setToolTipText] = useState("Total Number Of Time");
    const [maxHistoryDateSlider, setMaxHistoryDateSlider] = useState(0);
    const [minHistoryDateSlider, setMinHistoryDateSlider] = useState(0);


    const [sliderThumbsValue, setSliderThumbsValue] = useState([0, 0]);

  useEffect(() => {
    let dataCount = (maxCount-1);
    let tenPercentageOfMaxCount = Math.floor(parseFloat(parseFloat(dataCount)/10))
    let valuesOfMaxCountEachTenPercent = [1,2,3,4,5,6,7,8,9,10,11]
    for(let i=0; i<11; i++){
      valuesOfMaxCountEachTenPercent[i] = dataCount - (tenPercentageOfMaxCount * i)
    }   
    setSliderMarksValue(valuesOfMaxCountEachTenPercent )
    setSliderMarksLabel(["1","2","3","4","5","6","7","8","9","10","11"])
  }, [startDateOfChart, endDateOfChart, maxCount]);




  
  useEffect(() => {
    functionFetchSliderLabels() 
  }, [sliderMarksValue]);
  
  
  async function functionFetchSliderLabels() {
    let offsetOfLabel;
    let path;
   let labels =["1","2","3","4","5","6","7","8","9","10","11"]
   for(let i=0; i<11; i++){
    offsetOfLabel= sliderMarksValue[i];
    if(startDateOfChart === "Current Date"){
      path= `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${specialUrlPath}/?sensor_unique_identifier=${dataOfDevice.deviceID}&limit=1&offset=${offsetOfLabel}`
    }
    else{
      path = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${specialUrlPath}/?sensor_unique_identifier=${dataOfDevice.deviceID}&timestamp__range=${startDateOfChart},${endDateOfChart}&limit=1&offset=${offsetOfLabel}`
    }
    const response = await axios.get(path);

  if (typeof response.data.results[0] === 'undefined') {
    // gidebildiği yere kadar gidiyor, sonrasında daha fazla data yoksa undefined geliyor ve foru breakliyoruz.
    labels[i]="Data Not Found"
  }else{
    let response2 = response.data.results[0].timestamp
     labels[i]=moment(response2).format("DD/MM HH:mm");
  }
   }
   setSliderMarksLabel(labels)
    }

// gittikçe zaman düştüğü için labellarda ters düzen olacak. mesela 
// value 458000 ilk sırada value[0] dadır, bunu zamanda koyduğumuzda run123 de çalıştırdığımızda
// time[0] bizim en sondaki zamanı verecektir, ters orantı vardır.
const marksOfChartLineBorder = [

  {
    value: sliderMarksValue[0],
    label: `${sliderMarksLabel[10]}`,
  },
  {
    value: sliderMarksValue[1],
    label: `${sliderMarksLabel[9]}`,
  },
  {
    value: sliderMarksValue[2],
    label: `${sliderMarksLabel[8]}`,
  },
  {
    value: sliderMarksValue[3],
    label: `${sliderMarksLabel[7]}`,
  },
  {
    value: sliderMarksValue[4],
    label: `${sliderMarksLabel[6]}`,
  },
  {
    value: sliderMarksValue[5],
    label: `${sliderMarksLabel[5]}`,
  },
  {
    value: sliderMarksValue[6],
    label: `${sliderMarksLabel[4]}`,
  },
  {
    value: sliderMarksValue[7],
    label: `${sliderMarksLabel[3]}`,
  },
  {
    value: sliderMarksValue[8],
    label: `${sliderMarksLabel[2]}`,
  },
  {
    value: sliderMarksValue[9],
    label: `${sliderMarksLabel[1]}`,
  },
  {
    value: sliderMarksValue[10],
    label: `${sliderMarksLabel[0]}`,
  },
];

          function setChartOffsetAndLimit() {
            
            let max = maxCount - maxHistoryDateSlider;
            let min = maxCount - minHistoryDateSlider;
        
              let differenceOfMaxAndMin =  Math.abs(max-min);         // burada max ve min çubuğu (thumb) yer değiştirsede sonucu etkilemeyecektir
              let calculateOffset =  maxCount - maxHistoryDateSlider;
              
              functionOfSetLimit(differenceOfMaxAndMin);
              functionOffset(calculateOffset);
              setColorOfFetchingButton("info")             
          }

  useEffect(() => {     // this useEffect provide to after minHistoryDate is changed, set the offset And Limit
    if(initialRenderMin.current) {
      initialRenderMin.current = false;
    } else {
      setChartOffsetAndLimit();
    }
  }, [minHistoryDateSlider])


  useEffect(() => {     // this useEffect provide to after maxHistoryDate is changed, set the offset And Limit
    if(initialRenderMax.current) {
      initialRenderMax.current = false;
    } else {
      setChartOffsetAndLimit();
    }
  }, [maxHistoryDateSlider])


  useEffect(() => {     // this useEffect provide to after maxHistoryDate is changed, set the offset And Limit   
    let differenceBetweenToThumbsValue = sliderThumbsValue[1]-sliderThumbsValue[0]
    if(differenceBetweenToThumbsValue <  limitOfFetchingWarningDataAmountYellow){
      setColorOfFetchingButton("success")
      setToolTipText("we are in safe area.")
     }
     else if(differenceBetweenToThumbsValue > limitOfFetchingMaxDataAmountRed){
      setColorOfFetchingButton("error")
      setToolTipText("Maximum Data Limit exceeded. After this level,You can not fetch any data from database.Because Critical errors may occur in data fetching processes.")
    }
   else if(differenceBetweenToThumbsValue > limitOfFetchingWarningDataAmountYellow){
     setColorOfFetchingButton("warning")
     setToolTipText("Maximum Warning Limit exceeded. After this level, There may be slowdowns in graphics processing.")
    }
  }, [sliderThumbsValue])





    const changeTheSetSliderThumbsValue = (event, newValue, activeThumb) => {
        setSliderThumbsValue(newValue)
      }
    
  
    
      function materialSliderFetchData() {

let differenceOfThumbsValue = sliderThumbsValue[1]-sliderThumbsValue[0]; 
        if( differenceOfThumbsValue < limitOfFetchingMaxDataAmountRed ){
        setMaxHistoryDateSlider(sliderThumbsValue[1]);
        setMinHistoryDateSlider(sliderThumbsValue[0]);
        setColorOfFetchingButton("success")
       }
       else if(sliderMarksLabel[0] === "Data Not Found"){
        setColorOfFetchingButton("error")
       }
       else{
        setColorOfFetchingButton("error")
      }
    }
    
 function valuetext(value) {
    return `${value}°C merhaba`;
  }


    return (
        <div
        style={{
          marginLeft:"0.5rem",
          display: "flex",
        }}
        >
    <Grid container spacing={1}>
  
    <Grid item xs={0.7}>
    <div style={{alignContent:"center", alignItems:"center"}}>
    <Tooltip title={toolTipText} placement="bottom">
    <HelpCenterIcon color= {colorOfFetchingButton} fontSize="small"/> 
    </Tooltip>
    </div>
    </Grid>
  
<Grid item xs={9.8}>
<div
style={{marginTop:"-0.3rem",
alignContent:"center", 
alignItems:"center",
}}
>
            <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={sliderThumbsValue}
        max={maxCount}
        onChange={changeTheSetSliderThumbsValue}
        valueLabelDisplay="on"
        getAriaValueText={valuetext}
        disableSwap
        color={colorOfFetchingButton}
        marks = { marksOfChartLineBorder }
        size="small"
        sx={{
          '.MuiSlider-markLabel': {
            color: 'white',
          },
        }}
      />
      </div>
        </Grid>

        <div style={{alignContent:"center", alignItems:"center", marginLeft:"0.25rem"}}>
        <Grid item xs={1.5}>
  
       <Tooltip title={toolTipText} placement="bottom">
       <Badge badgeContent={sliderThumbsValue[1]-sliderThumbsValue[0]} color={colorOfFetchingButton} max={limitOfFetchingMaxDataAmountRed} size="small">
      <IconButton onClick={() => materialSliderFetchData() } color= {colorOfFetchingButton}   aria-label="add an alarm">
   { colorOfFetchingButton !=="error"  &&  <ScheduleSendIcon color= {colorOfFetchingButton} fontSize="small"/> }
   { colorOfFetchingButton ==="error"  &&  <CancelScheduleSendIcon color= {colorOfFetchingButton} fontSize="small"/> }
      </IconButton>
      </Badge>  
      </Tooltip>
    

        </Grid>
        </div>
        </Grid>
        </div>
    )
}
