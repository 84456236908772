import React from 'react'
import MainMqtt from './mqtt/MainMqqt'
import MainHistory from './history/MainHistory'


function MainChart({titles, url, dataTypes, timeLabel}) {
  return (
    <>
 <MainMqtt
 titles = {titles}
 url = {url}
 timeLabel={timeLabel}
 dataTypes = {dataTypes}  
 MainHistory={
 
 <MainHistory 
  titles = {titles}
  url = {url}
  dataTypes = {dataTypes}  
  
  />
} 
 />


    </>
  )
}

export default MainChart