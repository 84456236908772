/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PinInfo from "./PinInfo";
import { useSelector } from "react-redux";
import Badge from '@mui/material/Badge';
import PlaceIcon from '@mui/icons-material/Place';
import Tooltip from '@mui/material/Tooltip';
let mqttCli = require("mqtt");

let client;
let clientTwo;
// react memo kaldırdık belki ondan dolayı olmuştur

export default function Pin(props) {
  const {deviceList,  building } = props;

  const deviceListId = [];
  const [popupInfo, setPopupInfo] = useState(false);



  useEffect(() => {
    for (let i = 0; i < deviceList.length; i++) {
      statusOfDeviceList.set(deviceList[i].sensor_unique_identifier, 1);   
      pingOfDeviceList.set(deviceList[i].sensor_unique_identifier, 0)
      deviceListId.push(deviceList[i].sensor_unique_identifier);                                                   
    }
  }, []);


  const statusOfDeviceList = new Map();
  const pingOfDeviceList = new Map();

  
   
  const [environmentColor, setEnvironmentColor] = useState("rgb(255,255,255)");
  

  const [statusOfDeviceMap, setStatusOfDeviceMap] = useState(
    new Map(statusOfDeviceList)
  );
  const[totalMqttClosedDevice, setTotalMqttClosedDevice] = useState(0)


  const [pingOfDeviceMap, setPingOfDeviceMap] = useState(
    new Map(statusOfDeviceList)
  );
  const [totalUnreacheableDevice, setTotalUnreacheableDevice] = useState(0);

 

  const dataOfServer = useSelector((dataOfServer => dataOfServer.dataOfServer));
  const updateMap = (key, value) => {
    setStatusOfDeviceMap(new Map(statusOfDeviceMap.set(key, value)));
  };

  const updatePingMap = (key, value) => {
    setPingOfDeviceMap(new Map(pingOfDeviceMap.set(key, value)));
  };

  const optionsMqtt = {
    username: dataOfServer.serverMqttUser,
    password: dataOfServer.serverMqttPassword,
  };

  useEffect(() => {
    function startMqttSubscribe() {
      client = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
      clientTwo = mqttCli.connect(`${dataOfServer.serverWs}://${dataOfServer.serverIP}:${dataOfServer.serverMqttPort}`, optionsMqtt);
      
      client.subscribe(`watmon/connectivity/+`, () => {
        //When a message arrives, print it to the console
        client.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
          
    
        
          if(deviceListId.indexOf(dataAll["device"]) > -1){
            updatePingMap(dataAll["device"], dataAll["ping"])
          }
          

        });
      }
      );

      clientTwo.subscribe(`watmon/app/link_info/+`, () => {
        
        clientTwo.on("message", (topic, message, packet) => {
          let dataAll = JSON.parse(message);
          
        
        
          if(deviceListId.indexOf(dataAll["device"]) > -1){
            updateMap(dataAll["device"], dataAll["status"]);
          }
          

        });
      }
      );


      return client;
    }
    startMqttSubscribe();
    return () => {
      client && client.end();
      clientTwo && clientTwo.end();
    };
  }, []);


  



useEffect(() => {
  const mapOF =  pingOfDeviceMap;
  const counterOfOfflineDevice = [...mapOF.values()]
  let count = 0;
for(let i = 0; i < counterOfOfflineDevice.length; ++i){
    if(counterOfOfflineDevice[i] === 0){
      count++;
    }
}

  setTotalUnreacheableDevice(count)

}, [pingOfDeviceMap])




useEffect(() => {
  const mapOF= statusOfDeviceMap;
  const counterOfMqqtClosed = [...mapOF.values()]
  let count = 0;
for(let i = 0; i < counterOfMqqtClosed.length; ++i){
    if(counterOfMqqtClosed[i] === 0){
      count++;
    }
}
setTotalMqttClosedDevice(count)
 }, [statusOfDeviceMap])


 //decide to environment color with status and ping of devices

 useEffect(() => {
// firstly, the full color of 255. I am dividing with device numbers. 
let rgbDifference = 255 / deviceList.length;  // burada değişim miktarini ölçüyoruz. 
let decreaseRate = rgbDifference / 2;    //burada status ve ping e eşit miktarda oran veriyoruz. ikisinin toplamı 1 cihaz edecek şeklinde
let totalRedColor = decreaseRate * (totalMqttClosedDevice+totalUnreacheableDevice);
let newRgbCode = `rgb(${totalRedColor},${255 -totalRedColor},0)`;
setEnvironmentColor(newRgbCode)

 }, [totalMqttClosedDevice, totalUnreacheableDevice])
















function setPopup(value){
  setPopupInfo(value)
}


function CustomBadge({
  badgeContent,
  color,
  tooltipTitle,
  anchorOrigin,
  placement,
  children
}) {
  return (
    <Tooltip title={tooltipTitle} placement={placement}>
      <Badge
        badgeContent={badgeContent}
        color={color}
        size="small"
        anchorOrigin={anchorOrigin}
      >
        {children}
      </Badge>
    </Tooltip>
  );
}

  return (
    <div>
 <CustomBadge
      badgeContent={totalUnreacheableDevice}
      color="error"
      tooltipTitle="Number of Offline Devices"
      placement="right-start"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <CustomBadge
        badgeContent={totalMqttClosedDevice}
        color="warning"
        tooltipTitle="Number of Inactive Devices"
        placement="left-start"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <CustomBadge
          badgeContent={deviceList.length}
          color="primary"
          tooltipTitle="Total Device Number"
          placement="bottom-end"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <PlaceIcon
            sx={{ color: environmentColor, fontSize: 60 }}
            onClick={() => setPopupInfo((prev) => !prev)}
          />
        </CustomBadge>
      </CustomBadge>
    </CustomBadge>
    { popupInfo && <PinInfo setPopupOpening={setPopup} building={building} InfoDeviceListOnline={statusOfDeviceMap} deviceList={deviceList} deviceListPing = {pingOfDeviceMap}/> }
    
    </div>
  );
}
