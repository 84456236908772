import React, {  useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
function DevicePopUp() {
    const dataOfDevice = useSelector (dataOfDevice  => dataOfDevice.dataOfDevice)
    const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer);
     
    const [deviceData, setDeviceData] = useState()


    useEffect(() => {
      async function run() {
        const url = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/watmon/device_info/?sensor_unique_identifier=${dataOfDevice.deviceID}`;
        const responseOfDeviceData = await axios.get(url);
        setDeviceData(responseOfDeviceData.data.results[0]);
      
      }
      run();
    }, [dataOfDevice.deviceID])
    


   function convertDatetoPlain(timestamp){
    let date = new Date(timestamp);
    let formattedDate = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' +  date.getFullYear();
    return formattedDate
   }



  return (
    <div style={{color:"black"}}>
        <p ><span style={{fontWeight:"bold"}}>Device Name:</span> {deviceData?.name}</p>
        <p ><span style={{fontWeight:"bold"}}>Device ID:</span> {deviceData?.sensor_unique_identifier}</p>
        <div>
            <p><span style={{fontWeight:"bold"}}>Device Hardware: </span>{deviceData?.info}</p>
        </div>
        <p ><span style={{fontWeight:"bold"}}>Device Registration: </span>{convertDatetoPlain(deviceData?.timestamp)}</p>
        <div
         style={{
          alignItems:"center",
          alignContent:"center"
         }}>
        </div>
    </div>
  )
}

export default DevicePopUp