import React, { useEffect, useState} from "react";
import { Line, Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import options from './ChartComponentOptionsOld'
import BinButtons from './BinButtons'
import {convertFullDateLabeltoMonthAndHour, getStandardDeviation, calculateEachMeanofArrayElement } from './ArrayFunctions'
import moment from "moment";
import ChartSettingSliders from './ChartSettingSliders'

Chart.register(annotationPlugin);
Chart.register(zoomPlugin);
// min and max slider in isimleri yanıltmasın, min en sonda olan, max ise geçmişe gidebilmemize yardım eder.
const ChartComponent = ({
  fetchedDataValue,
  fetchedDataLabel,
  title,
  ChangeMainDataType,
  titles
}) => {
 const [dataValue, setDataValue] = useState([])
 const [dataLabel, setDataLabel] = useState([])
 const [binType, setBinType] = useState([])


// const [chartNoConnectionAreas, setChartNoConnectionAreas] = useState([]);
    useEffect(() => {
        let mockupValue = fetchedDataValue;
        let mockupLabel = fetchedDataLabel;
        let holdingBinValue = [];
        let holdingBinLabel = [];
        if(binType === "standard"){
            setDataValue(fetchedDataValue)
            setDataLabel(convertFullDateLabeltoMonthAndHour(fetchedDataLabel))
        }
        else{
        for(let i=0; i< mockupLabel.length; i++){
        let mean = mockupValue[i];
        let j = 1;
        let counterOfWhileLoop = 1;
        let date = mockupLabel[i];
        while(true){
        if( moment(mockupLabel[i]).isSame(mockupLabel[i+j], binType)){
            mean = (mockupValue[i+j] + mean * counterOfWhileLoop) / (counterOfWhileLoop+1)
            j++;
            counterOfWhileLoop++;
           if((i+j+1) > (mockupLabel.length)){
            holdingBinValue.push(mean);
            holdingBinLabel.push(date);
            break;
           }
           
        }else{         
            holdingBinValue.push(mean);
            holdingBinLabel.push(date);
            break;
        }
        }
        i = i + j - 1;
        
        }

        setDataValue(holdingBinValue)
        setDataLabel(convertFullDateLabeltoMonthAndHour(holdingBinLabel)) 
        }
      
    },[binType, fetchedDataValue, fetchedDataLabel]);


function funcSetBinType(receivedBinType){
    setBinType(receivedBinType)
}


  useEffect(() => {

  if(dataValue.length > 1500)
  {
    setBorderWidthValueMean(0.01)
    setBorderLineShow(false)
    setChartPointRadiusValueMean(1)
  }else if(dataValue.length > 100){
    setBorderLineShow(true)
    setBorderWidthValueMean(0.5)
    setBorderWidthValue(1)
    setChartPointRadiusValueMean(1)
  }
  
  
  else{
    setBorderWidthValueMean(2.5)
    setBorderLineShow(true)
    setBorderWidthValue(2.5)
    setChartPointRadiusValueMean(2.5)
  }
    
  }, [dataValue]);

  const [borderWidthValueMean, setBorderWidthValueMean] = useState(2.5);
  const [borderWidthValue, setBorderWidthValue] = useState(2.5);
  const [chartPointRadiusValue, setChartPointRadiusValue] = useState(2.5);
  const [chartPointRadiusValueMean, setChartPointRadiusValueMean] = useState(2.5);

  const [borderLineShow, setBorderLineShow] = useState(true);
  const [backGroundShow, setBackGroundShow] = useState(false);

  function changeChartRangeLineDrawingBorder(event,e) {
    setBorderWidthValue(e);
  }

  function changeChartRangeLineDrawingBorderMean(event, e) {
    setBorderWidthValueMean(e);
  }

  function changeChartRangePointRadius(event, e) {
    setChartPointRadiusValue(e);
  }

  function changeChartRangePointRadiusMean(event,e) {
    setChartPointRadiusValueMean(e);
  }

  function disableChartLine(e) {
    setBorderLineShow(e.target.checked);
  }

  function fillChartBackGround(e) {
    setBackGroundShow(e.target.checked);
  }

  return (
<div
    style={{
      height: "70vh",
      width: "70vw",
      alignItems: "center",
      alignContent: "center",
      alignSelf: "center",
      position:"relative",
    }} 
>
<ChartSettingSliders 
    borderWidthValueMean={borderWidthValueMean}
    borderWidthValue={borderWidthValue}
    chartPointRadiusValue={chartPointRadiusValue}
    chartPointRadiusValueMean={chartPointRadiusValueMean}
    borderLineShow={borderLineShow}
    backGroundShow={backGroundShow}
    changeChartRangeLineDrawingBorder={changeChartRangeLineDrawingBorder}
    changeChartRangeLineDrawingBorderMean={changeChartRangeLineDrawingBorderMean}
    changeChartRangePointRadius={changeChartRangePointRadius}
    changeChartRangePointRadiusMean={changeChartRangePointRadiusMean}
    disableChartLine={disableChartLine}
    fillChartBackGround={fillChartBackGround}
/>
    <BinButtons funcSetBinType= {funcSetBinType} ChangeMainDataType = {ChangeMainDataType}  titles={titles} />
        <Line
             style={{
              height: "70vh",
              width: "70vw",
              alignItems: "center",
              alignContent: "center",
              alignSelf: "center",
              position:"relative",
            }} 
          data={{
            labels: dataLabel,
            datasets: [
              {
                label: `Mean of the ${title}`,
                data: calculateEachMeanofArrayElement(dataValue),
                borderColor: "rgb(34, 233, 226)",
                color:"rgb(34, 233, 226)",
                backgroundColor:"rgba(34, 233, 226,0.3)",
                showLine: true,
                pointRadius: chartPointRadiusValueMean,
                hoverRadius: 7,
                hoverBorderWidth: 7,
                borderWidth: borderWidthValueMean,
              },
              {
                label: `${title}`,
                data: dataValue,
                borderColor: "#03c782",
                showLine: borderLineShow,
                pointRadius: chartPointRadiusValue,
                backgroundColor: "#03c782",
                fill: backGroundShow,
                hoverRadius: 7,
                hoverBorderWidth: 7,
              },
            ],
          }}
          options={options(borderWidthValue, 
            (dataValue.length ? dataValue.reduce((a, b) => Math.max(a, b)) : 0), 
            (dataValue.length ? dataValue.reduce((a, b) => Math.min(a, b)) : 0), 
            getStandardDeviation(dataValue), 
            title, 
            )}
        />
       
      </div>
  );
};
export default ChartComponent;
