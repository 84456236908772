import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import ChartComponent from "./ChartComponentBins";
import Box from "@mui/material/Box";
import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ChartHistorySlider from "../../history/ChartHistorySlider";
export default function MainHistory({
  titles = ["total_retransmissions"],
  url = "network_traffic/protocol_density",
  dataTypes = [
    "total_retransmissions",
  ],

}) {


    const dataOfTime = useSelector((dataOfTime) => dataOfTime.dataOfTime);
    const [limit, setLimit] = useState(300);
    const [offset, setOffset] = useState(0);
    const [maxCount, setMaxCount] = useState(0);
    const [startDateOfChart, setStartDateOfChart] = useState(" ");
    const [endDateOfChart, setEndDateOfChart] = useState(" ");
    const dataOfServer = useSelector((dataOfDevice) => dataOfDevice.dataOfServer); 
    const dataOfDevice = useSelector((dataOfDevice) => dataOfDevice.dataOfDevice);
  


    const holderDataLabels = [];
    const holderFirstDataType = [];
    const [firstDataTypeArray, setFirstDataTypeArray] = useState([]);



    const [dataLabels, setDataLabels] = useState([]);
    const [wholeDataSet, setWholeDataSet] = useState();

    const [lineData, setLineData] = useState([]);
    const [barData, setBarData] = useState([]);
     
 
    const color = [
      "rgb(160, 195, 210)",
      "rgb(232, 223, 202)",
      "rgb(234, 199, 199)",
      "rgb(176, 139, 187)",
      "rgb(134, 200, 188)",
      "rgb(255, 212, 178)",
      "rgb(255, 209, 232)",
      "rgb(223, 211, 195)",
      "rgb(232, 243, 214)",
      "rgb(101, 100, 124)",
      "rgb(183, 196, 207)",
      "rgb(150, 126, 118)",
    ];


    function prepareDatasets(titles, twoDimensionalArray, lineData) {
      const datasetsTwo = titles.map((title, index) => ({
        label: title,
        data: twoDimensionalArray[index],
        backgroundColor: color[index],
        yAxisID: "y",
        order: 2,
      }));
      const dataSetsThree = {
        type: "line",
        label: "Total Retransmissions",
        data: lineData,
        borderColor: "rgb(233, 119, 119)",
        backgroundColor: "rgb(233, 119, 119)",
        yAxisID: "y1",
        order: 1,
        showLine: true,
        borderWidth: 10 * 1.0 / lineData.length  
      };
      const wholeDataSets = datasetsTwo.concat(dataSetsThree);
      return wholeDataSets;
    }

  useEffect(() => {
  functionOfStartDateOfChart(String(dataOfTime.startDateInfo));
  functionOfEndDateOfChart(String(dataOfTime.endDateInfo));
  }, [dataOfTime]);
  
  useEffect(() => {
    setLimit(10)
  }, [startDateOfChart, endDateOfChart]);
  
    useEffect(() => {
      async function run() {
        try{


  
        let path;
        if(startDateOfChart === "Current Date"){
          path= `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${url}/?sensor_unique_identifier=${dataOfDevice.deviceID}&limit=${limit}&offset=${offset}`
        }
        else{
          path = `${dataOfServer.serverHttp}://${dataOfServer.serverIP}:${dataOfServer.serverPORT}/${url}/?sensor_unique_identifier=${dataOfDevice.deviceID}&timestamp__range=${startDateOfChart},${endDateOfChart}&limit=${limit}&offset=${offset}`
        
        }
        const response = await axios.get(path);
        setMaxCount((response.data.count-1));
        const response2 = response.data.results;

        const types = [];
        const dataTwo = [];
        const labels = [];
        const lineData = [];



        for (let i = 0; i < limit; i++) {
          if (typeof response2[i] == "undefined") {   
            break;
          }
  
          let dataAll = response2[i];

      
          for (let i = 0; i < dataAll.type.length; i++) {
            if (!types.includes(dataAll.type[i])) {
              types.push(dataAll.type[i]);
              let newArray = [];
              for (let k = 0; k < labels.length; k++) {
                newArray.push(0);
              }
              newArray.push(dataAll.density[i]);

              dataTwo.push(newArray);
            } else {
              dataTwo[types.indexOf(dataAll.type[i])].push(
                dataAll.density[i]
              );
            }
          }

          labels.push(dataAll.end_date);
          lineData.push(dataAll.total_retransmissions);

          for (let j = 0; j < dataTwo.length; j++) {
            if (dataTwo[j].length < labels.length) dataTwo[j].push(0);
          }
          








          if(response2[i][dataTypes[0]] || response2[i][dataTypes[0]] === 0) holderFirstDataType.push(response2[i][dataTypes[0]]);
       //   holderDataLabels.push(moment(response2[i].timestamp).format("YYYY-MM-DD HH:mm"));
         


          if (i === limit - 1) {
            break;
          }
        }
        
        /*
        şimdi, normalde biz arrayleri ters çeviriyorduk. tarih soldan sağa doğru ilerlemesi için.
        2d arrayde ise 2d arrayin her birini terse çevireceğiz. sonrasında yenisine ekleyeceğiz.
        */
        
        const reverseDataTwo = []
        for(let k = 0 ; k < dataTwo.length ; k++){
          let newArray = dataTwo[k].reverse();
          reverseDataTwo.push(newArray);

        }
        
        

        setWholeDataSet(prepareDatasets( types, reverseDataTwo, lineData));
        setFirstDataTypeArray(holderFirstDataType.reverse());
        setDataLabels(labels.reverse());

        setLineData(lineData)
        setBarData(reverseDataTwo)
        
        let toplamvalue = 0;
        for(let i=0; i < reverseDataTwo[0].length; i++ ){
        toplamvalue = reverseDataTwo[0][i] + toplamvalue;
        }
        
      }catch(e){

      }
      }
      run();
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, startDateOfChart, endDateOfChart]);
  

    function functionOfSetLimit(valueOfLimit) { setLimit(valueOfLimit); }
    function functionOffset(valueOfOffset) { setOffset(valueOfOffset); }
    function functionOfStartDateOfChart(valueOfStartDate) { setStartDateOfChart(valueOfStartDate); }
    function functionOfEndDateOfChart(valueOfEndDate) {setEndDateOfChart(valueOfEndDate);}
  
  

  const styleOfSettingBlock = {
    position: "absolute",
    top: "relative",
    zIndex: "3",
    border: "1px solid rgba(0, 0, 0, 0.04)",
    boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.3)",
    background: "rgba(255,255,255,0.85)",

  };

  const [openBox, setOpenBox] = useState(false);
  function setBoxOpen() {
    setOpenBox(old => !old);
  }

  const handleClickAway = () => {
    setOpenBox(false);
  };
  


  return (
    <>
    <ClickAwayListener onClickAway={handleClickAway}>
    <div>
      <>
      <Tooltip title={"History"} placement="bottom">
<IconButton color="primary" onClick={() => setBoxOpen()} >
<HistoryIcon />
  </IconButton>
  </Tooltip>      
      </>
    <div>
    {openBox ? (
    <Box sx={styleOfSettingBlock}>
    <div>
    <div>
      <div className={"chart-wrapperPopUp"}>
        <ChartComponent
          
          labels={dataLabels}
          
          receivedwholeDataSet = {wholeDataSet}
        />
              <ChartHistorySlider
    maxCount={maxCount}
    functionOfSetLimit={functionOfSetLimit}
    functionOffset={functionOffset}
    startDateOfChart = {startDateOfChart}
    endDateOfChart = {endDateOfChart}
    specialUrlPath = {url}
    />
      </div>

    </div>
    </div>
  </Box>
  ) : null}
  </div>
  </div>
  </ClickAwayListener>
  </>
  );
}

